import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  Font,
  // $FlowFixMe
} from '@react-pdf/renderer';
// import arialFont from './../../assets/fonts/arial.ttf';
// import arialBdFont from './../../assets/fonts/arialBd.ttf';

const CONTENT_ITEM_TYPE = {
  IMAGE: 'image',
  TEXT: 'text',
  LINK: 'link',
  COMPONENT: 'component',
};

Font.register({
  family: 'Arial',
  fonts: [
    {
      src: '/fonts/arial.ttf',
      fontWeight: 300,
    },
    {
      src: '/fonts/arialBd.ttf',
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: '100%',
  },
  page: { padding: '70px' },
  pageHead: { padding: 0 },
  heading1: {
    fontSize: '18px',
    fontFamily: 'Arial',
    color: '#000000',
    marginBottom: '10px',
  },
  heading2: {
    fontSize: '14px',
    fontFamily: 'Arial',
    color: '#000000',
    marginBottom: '10px',
  },
  heading3: {
    fontSize: '12px',
    fontFamily: 'Arial',
    color: '#000000',
    marginBottom: '10px',
  },
  defaultParagrpah: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    color: '#000000',
    marginBottom: '10px',
  },
  boldParagrpah: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    fontWeight: 700,
    color: '#000000',
    marginBottom: '5px',
  },
  listParagrpah1: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    color: '#000000',
    margin: '0 0 5px 24px',
  },
  listParagrpah2: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    color: '#000000',
    margin: '0 0 5px 48px',
  },
  linkParagrpah: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    color: '#5F5F5F',
    marginBottom: '5px',
  },
});

const buildPdfPageContent = (content) =>
  content.map((item, index) => {
    const { type, className = '', additionalStyles = {} } = item;
    const key = `pdfContent_${index}`;
    let pdfItem = null;

    if (type === CONTENT_ITEM_TYPE.IMAGE) {
      pdfItem = (
        <Image
          key={key}
          src={item.src}
          style={[styles[className], additionalStyles]}
        />
      );
    } else if (type === CONTENT_ITEM_TYPE.TEXT) {
      pdfItem = (
        <Text key={key} style={[styles[className], additionalStyles]}>
          {item.children ? buildPdfPageContent(item.children) : item.value}
        </Text>
      );
    } else if (type === CONTENT_ITEM_TYPE.LINK) {
      pdfItem = (
        <Link
          key={key}
          style={[styles[className], additionalStyles]}
          src={item.src}
        >
          {item.value}
        </Link>
      );
    } else if (type === CONTENT_ITEM_TYPE.COMPONENT) {
      pdfItem = <React.Fragment key={key}>{item.component}</React.Fragment>;
    }

    return pdfItem;
  });

const PdfGenerator = (props) => {
  const { config } = props;

  return (
    <Document>
      {config.map((pageConfig, index) => {
        const { page, content } = pageConfig;
        const { size, className: pageClassName } = page;
        const key = `pdfPage_${index}`;
        const pageContent = buildPdfPageContent(content);

        return (
          <React.Fragment key={key}>
            <Page size={size} style={styles[pageClassName]}>
              <View>{pageContent}</View>
            </Page>
          </React.Fragment>
        );
      })}
    </Document>
  );
};

export default PdfGenerator;
