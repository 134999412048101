import ReactGA from 'react-ga4';

export const initGA = (id) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize([
      {
        trackingId: id,
        redactEmail: false,
        gaOptions: {
          redactEmail: false,
          anonymizeIp: true,
          allowAdFeatures: false,
        },
      },
    ]);
  }
};

export const event = (category, action, context) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event({
      category,
      action,
      label: JSON.stringify(context),
    });
  }
};

export const pageview = (path) => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.pageview(path);
  }
};
