/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { checkLogin } from '../../actions/checkLogin';
import { UpdatePasswordResetUsername } from '../../actions/gui';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { checkLogin, UpdatePasswordResetUsername },
    dispatch,
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  gui: state.gui,
});

const styles = () => ({
  root: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    padding: 15,
    textAlign: 'center',
    background: '#22252a',
    opacity: '0.90',
    borderRadius: 4,
    margin: '5px auto',
    marginTop: '20px',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  forminput: {
    // color: 'black',
    minWidth: '300px',
    width: 200,
  },
  arrowfont: {
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  leftalign: {
    textAlign: 'left',
  },
});

class PasswordResetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      alert: {
        msg: 'Enter your email address to continue',
        status: '',
      },
    };
  }

  loginChange = (e) => {
    this.setState({ login: e.target.value });
  };

  Cancel = () => {
    window.location.href = process.env.REACT_APP_COMMON_URL;
  };

  resetPassword = (event) => {
    const { login } = this.state;
    const {
      history,
      UpdatePasswordResetUsername: UpdatePasswordResetUsernameAlt,
    } = this.props;

    event.preventDefault();

    if (!login) {
      this.setState({
        alert: { msg: 'Login cannot be empty.', status: 'alert' },
      });
    } else {
      UpdatePasswordResetUsernameAlt(login);
      Auth.forgotPassword(login)
        .then(() => {
          document.getElementById('form-inputs').innerHTML = '';
          history.push('/passwordresetconfirm');
        })
        .catch((err) =>
          this.setState({ alert: { msg: err.message, status: 'alert' } }),
        );
    }
  };

  render() {
    const { classes } = this.props;
    const { alert } = this.state;

    return (
      <Container className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.arrowfont} gutterBottom>
            Reset Password
          </Typography>

          <p className={alert.status}>{alert.msg}</p>
          <form id="form-inputs" onSubmit={this.resetPassword}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              style={{ marginTop: '40px' }}
            >
              <Grid item xs={12}>
                <TextField
                  required
                  className={classes.forminput}
                  label="E-mail"
                  fullWidth
                  onChange={this.loginChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={this.Cancel}>
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button type="submit" variant="contained" color="primary">
                  Proceed
                </Button>
              </Grid>
              <Grid item xs={12}>
                <a
                  href="/#register"
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  {"Don't have an account ?"}
                </a>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    ); // return end
  } // render end
} // class LoginPage end
PasswordResetPage.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage)),
);
