import React, { Component } from 'react';

class FileList extends Component {
  render() {
    const { inputs } = this.props;

    return (
      <div className="filelist">
        {inputs.length > 0 ? <h4>List of input files:</h4> : null}

        <ul>
          {inputs.map((file) => (
            <li key={file.filename}>
              <a>{file.filename}</a>
            </li>
          ))}
        </ul>
        <br />
      </div>
    );
  }
}

export default FileList;
