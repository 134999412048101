/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ShareIcon from '@material-ui/icons/Share';
import SaveIcon from '@material-ui/icons/Save';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import JsonIcon from '@material-ui/icons/Code';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Backdrop from '@material-ui/core/Backdrop';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { pdf } from '@react-pdf/renderer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import PdfGenerator from '../PdfGenerator/PdfGenerator';
import { ApiConfigSave, ApiConfigSavePrepare } from '../../actions/ApiConfig';
import { ApiSharedSnippetSave } from '../../actions/ApiSharedSnippet';
import { event } from '../../utils/ga-utils';
import { createGuid } from '../../utils/guid-utils';
import LoadDialog from '../LoadDialog';
import SaveDialog from '../SaveDialog';
import ShareStep2Dialog from '../ShareStep2Dialog';
import ShareStep1Dialog from '../ShareStep1Dialog';
import ReportDialog from '../ReportDialog';
import { getPdfStructureConfig } from '../../utils/pdfConfig';
import { maskIPAddresses } from '../../utils/net-utils';

import {
  exportConf,
  exportJson,
  exportPdfReport,
} from '../../actions/fetchConf';
import { GuiToolbarChange } from '../../actions/gui';

const styles = {
  root: {
    flexGrow: 1,
  },
  buttonText: {
    textDecoration: 'none',
    textTransform: 'none',
    marginLeft: '10px',
  },
};

const mapStateToProps = (state) => ({
  configRaw: state.config.configRaw,
  configFiltered: state.config.configFiltered,
  activeShareSnippetUrl: state.config.activeShareSnippetUrl,
  APIConfigs: state.apiconfigs,
  GUI: state.gui,
  auth: state.auth,
  status: state.status,
  analysisItems: state.config.analysisItems,
  stats: state.config.stats,
  fileName: state.config.fileName,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ApiConfigSave,
      ApiSharedSnippetSave,
      ApiConfigSavePrepare,
      GuiToolbarChange,
      exportConf,
      exportJson,
      exportPdfReport,
    },
    dispatch,
  );
}

const style = {
  background: '#464C54',
  paddingLeft: '12px',
};

const buttonStyle = {
  minWidth: 30,
  padding: '8px 8px',
};

class ToolBar extends Component {
  constructor(props) {
    super(props);

    //  this.toggleBtn = this.toggleBtn.bind(this)

    this.shareBtn = this.shareBtn.bind(this);
    this.saveBtn = this.saveBtn.bind(this);
    this.saveBtnFinal = this.saveBtnFinal.bind(this);
    this.loadBtn = this.loadBtn.bind(this);
    this.downloadBtn = this.downloadBtn.bind(this);
    this.handleSaveDialogClose = this.handleSaveDialogClose.bind(this);
    this.handleLoadDialogClose = this.handleLoadDialogClose.bind(this);
    this.handleShareStep1DialogClose =
      this.handleShareStep1DialogClose.bind(this);
    this.handleLoginDialogClose = this.handleLoginDialogClose.bind(this);
    this.handleReportDialogClose = this.handleReportDialogClose.bind(this);
    this.handleShareStep2DialogClose =
      this.handleShareStep2DialogClose.bind(this);
    this.inputConfigName = React.createRef();

    this.state = {
      saveDialogOpen: false,
      shareStep1DialogOpen: false,
      shareStep2DialogOpen: false,
      loginDialogOpen: false,
      loadDialogOpen: false,
      reportDialogOpen: false,
    };
  }

  handleSaveDialogOpen() {
    this.setState({ saveDialogOpen: true });
  }

  handleLoginDialogOpen() {
    this.setState({ loginDialogOpen: true });
  }

  handleLoadDialogOpen() {
    this.setState({ loadDialogOpen: true });
  }

  handleReportDialogOpen() {
    this.setState({ reportDialogOpen: true });
  }

  handleShareStep1DialogOpen() {
    this.setState({
      shareValidity: null,
      shareMaskIP: null,
      shareDisableAnalysis: false,
    });
    this.setState({ shareStep1DialogOpen: true });
  }

  handleShareStep2DialogOpen(shareValidity, shareMaskIP, shareDisableAnalysis) {
    const { configFiltered } = this.props;

    this.setState({ shareStep2DialogOpen: true });

    // check if unique id already or not
    // if not,
    // create sharedSnippet:
    if (configFiltered) {
      if (configFiltered.objects) {
        let exportlines = [];

        for (
          let i = 0;
          i < Object.keys(configFiltered.objects).length;
          i += 1
        ) {
          const objecttype = Object.keys(configFiltered.objects)[i];
          const objectitems = configFiltered.objects[objecttype].items;

          exportlines = exportlines.concat(objectitems);
        }

        // mask IP
        if (shareMaskIP) {
          exportlines.forEach((element, index) => {
            exportlines[index].baseline = maskIPAddresses(element.baseline);
          });
        }

        // sort on line number
        const sorted = exportlines.sort((a, b) => (a.line > b.line ? 1 : -1));
        const code = sorted.map((item) => item.baseline).join('\n');
        const sharedSnippetId = createGuid();

        this.shareSnippet(
          sharedSnippetId,
          code,
          shareValidity,
          shareDisableAnalysis,
        );
      }
    }
  }

  handleLoadDialogClose = () => {
    const { history } = this.props;

    this.setState({ loadDialogOpen: false });
    history.push('snippet');
  };

  handleSaveDialogClose = (value) => {
    const { configRaw, activeFilter } = this.props;

    if (value === true) {
      const filename = document.getElementById('configsave-name').value;
      const description = document.getElementById('configsave-desc').value;
      const customer = document.getElementById('configsave-company').value;

      this.saveConfig(filename, configRaw, activeFilter, description, customer);
    }
    this.setState({ saveDialogOpen: false });
  };

  handleReportDialogClose = async (value) => {
    console.log('report dialog close, value = ', value);
    const {
      exportPdfReport: exportPdfReportAlt,
      // pdfReportProps,
      auth,
      analysisItems,
      stats,
      fileName,
    } = this.props;

    // const { url } = pdfReportProps;
    if (value === true) {
      const currentCustomer = document.getElementById(
        'report-customername',
      ).value;
      const currentCustomerContact = document.getElementById(
        'report-customercontact',
      ).value;
      // updatePDFConfig({
      //   customerName: currentCustomer,
      //   customerContact: currentCustomerContact,
      // });
      const pdfConfig = getPdfStructureConfig(
        analysisItems
          ? [
              {
                analysis: { analysisItems, stats, filterByMatched: true },
                instanceName: stats.hostname || fileName,
              },
            ]
          : [],
        {
          companyName: currentCustomer,
          firstName: currentCustomerContact,
          lastName: '',
        },
        {
          id: fileName,
        },
      );

      // const [instance, updateInstance] = usePDF({
      //   document: <PdfGenerator config={pdfConfig} />,
      // });
      const pdfDoc = pdf(<PdfGenerator config={pdfConfig} />);

      console.log('before toBlob');
      try {
        const blob = await pdfDoc.toBlob();

        console.log('blob, ', blob);
        event('User', 'Export PDF', auth.username.replace('@', '_'));

        exportPdfReportAlt(blob);
      } catch (err) {
        console.log('error during toBlob: ', err);
      }
    }
    this.setState({ reportDialogOpen: false });
  };

  handleShareStep1DialogClose = (
    value,
    shareValidity,
    shareMaskIP,
    shareDisableAnalysis,
  ) => {
    if (value === true) {
      this.setState({ shareStep1DialogOpen: false });
      this.handleShareStep2DialogOpen(
        shareValidity,
        shareMaskIP,
        shareDisableAnalysis,
      );
    }
    this.setState({ shareStep1DialogOpen: false });
  };

  handleShareStep2DialogClose() {
    this.setState({ shareStep2DialogOpen: false });
  }

  handleLoginDialogClose() {
    this.setState({ loginDialogOpen: false });
  }

  shareBtn = (e) => {
    const { auth } = this.props;

    e.preventDefault();
    if (auth.isAuthenticated) {
      this.handleShareStep1DialogOpen();
    } else {
      this.handleLoginDialogOpen();
    }

    return 0;
  };

  loadBtn = (e) => {
    e.preventDefault();
    this.handleLoadDialogOpen();

    return 0;
  };

  downloadBtn = () => {
    const { exportConf: exportConfAlt, auth } = this.props;

    exportConfAlt(auth);

    // download snippet
    return true;
  };

  saveBtn = () => {
    const { auth } = this.props;

    //    console.log("saveBtn");
    // e.preventDefault()
    if (auth.isAuthenticated) {
      this.handleSaveDialogOpen();
    } else {
      this.handleLoginDialogOpen();
    }
  };

  handleExport = () => {
    const { exportJson: exportJsonAlt, auth } = this.props;

    event('User', 'Export JSON', auth.username.replace('@', '_'));
    exportJsonAlt();
  };

  handlePdfReport = () => {
    const { auth } = this.props;

    //    console.log("saveBtn");
    // e.preventDefault()
    if (auth.isAuthenticated) {
      this.handleReportDialogOpen();
    } else {
      this.handleLoginDialogOpen();
    }
  };

  shareSnippet(
    sharedSnippetId,
    configRaw,
    shareValidity,
    shareDisableAnalysis,
  ) {
    const { ApiSharedSnippetSave: ApiSharedSnippetSaveAlt, auth } = this.props;

    ApiSharedSnippetSaveAlt({
      sharedSnippetId,
      configRaw,
      auth,
      shareValidity,
      shareDisableAnalysis,
    });
  }

  saveConfig(filename, configRaw, activeFilters, description, customer) {
    const { ApiConfigSave: ApiConfigSaveAlt, auth } = this.props;

    ApiConfigSaveAlt({
      filename,
      configRaw,
      activeFilters,
      description,
      customer,
      auth,
    });
  }

  saveBtnFinal() {
    const { configRaw, activeFilter } = this.props;
    const configname = this.inputConfigName.current.value;

    this.saveConfig(configname, configRaw, activeFilter, '', '');
  }

  render() {
    const {
      APIConfigs,
      status,
      activeShareSnippetUrl,
      classes,
      auth,
      location,
    } = this.props;
    const {
      loadDialogOpen,
      saveDialogOpen,
      shareStep1DialogOpen,
      shareStep2DialogOpen,
      loginDialogOpen,
      reportDialogOpen,
    } = this.state;

    if (APIConfigs.isError) {
      console.error(JSON.stringify(APIConfigs.errorMessage));
    }

    const { isAuthenticated, td } = auth;

    let loading;

    if (status.isConfigActivity) {
      loading = (
        <Backdrop className="backdrop" style={{ zIndex: 99 }} open>
          <CircularProgress color="inherit" />
          &nbsp;&nbsp; {status.currentActivity}
        </Backdrop>
      );
    } else {
      loading = '';
    }

    return (
      <div>
        <Toolbar style={style} variant="dense">
          <a>
            <Tooltip title="Load Config">
              <Button style={buttonStyle} onClick={this.loadBtn}>
                <NoteAddIcon />
                <Typography className={classes.buttonText}>
                  Load Config
                </Typography>
              </Button>
            </Tooltip>
          </a>
          {location.pathname !== '/configs' ? (
            <a>
              <Tooltip title="Share">
                <Button onClick={this.shareBtn} style={buttonStyle}>
                  <ShareIcon />
                  <Typography className={classes.buttonText}>Share</Typography>
                </Button>
              </Tooltip>
            </a>
          ) : null}
          {location.pathname !== '/configs' ? (
            <a>
              <Tooltip title="Download">
                <Button onClick={this.downloadBtn} style={buttonStyle}>
                  <CloudDownloadIcon />
                  <Typography className={classes.buttonText}>
                    Download
                  </Typography>
                </Button>
              </Tooltip>
            </a>
          ) : null}
          {location.pathname !== '/configs' ? (
            <a>
              <Tooltip title="Save">
                <Button onClick={this.saveBtn} style={buttonStyle}>
                  <SaveIcon />
                  <Typography className={classes.buttonText}>Save</Typography>
                </Button>
              </Tooltip>
            </a>
          ) : null}
          {isAuthenticated && td && location.pathname !== '/configs' ? (
            <a>
              <Tooltip title="Export JSON">
                <Button onClick={this.handleExport} style={buttonStyle}>
                  <JsonIcon />
                  <Typography className={classes.buttonText}>
                    Export JSON
                  </Typography>
                </Button>
              </Tooltip>
            </a>
          ) : null}
          {isAuthenticated && location.pathname !== '/configs' ? (
            <a>
              <Tooltip title="Create Report">
                <span>
                  <Button onClick={this.handlePdfReport} style={buttonStyle}>
                    <GetAppIcon />
                    <Typography className={classes.buttonText}>
                      Create Report
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
            </a>
          ) : null}
        </Toolbar>

        {loading}

        <LoadDialog
          open={loadDialogOpen}
          onClose={this.handleLoadDialogClose}
        />

        <SaveDialog
          open={saveDialogOpen}
          onClose={this.handleSaveDialogClose}
        />

        <ShareStep1Dialog
          open={shareStep1DialogOpen}
          onClose={this.handleShareStep1DialogClose}
        />

        <ShareStep2Dialog
          open={shareStep2DialogOpen}
          onClose={this.handleShareStep2DialogClose}
          activeShareSnippetUrl={activeShareSnippetUrl}
        />

        <ReportDialog
          open={reportDialogOpen}
          onClose={this.handleReportDialogClose}
        />

        <Dialog
          open={loginDialogOpen}
          onClose={this.handleLoginDialogClose}
          aria-labelledby="Required to logon"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">Log On</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This feature requires you to be logged in.
              <a href="#login">
                <Button color="secondary">Logon</Button>
              </a>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleLoginDialogClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
ToolBar.propTypes = {
  // classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ToolBar)),
);
