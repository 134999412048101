/* eslint-disable no-console */
export const ADD_INPUT = 'ADD_INPUT';

export function addInput(payload) {
  console.log('action: addInput');

  return {
    type: ADD_INPUT,
    payload,
  };
}
