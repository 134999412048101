/* jshint esversion: 9 */
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import confReducer from './reducer_conf/index';
import authReducer from './reducer_auth/index';
import apiconfigsReducer from './reducer_apiconfigs/index';
import apitemplatesReducer from './reducer_apitemplates/index';
import apisettingsReducer from './reducer_apisettings/index';
import guiReducer from './reducer_gui/index';
import statusReducer from './reducer_status/index';
import healthcheckReducer from './reducer_healthcheck';

const persistConfig = {
  key: 'config',
  storage: storageSession,
  // blacklist: ['configFiltered'],
  stateReconciler: autoMergeLevel2,
};
// export default function rootReducer(state, action) {
//   return {
//     ...state,
//     config: persistReducer(persistConfig, confReducer(state.config, action)),
//     auth: authReducer(state.auth, action),
//     apiconfigs: apiconfigsReducer(state.apiconfigs, action),
//     apitemplates: apitemplatesReducer(state.apitemplates, action),
//     apisettings: apisettingsReducer(state.apisettings, action),
//     gui: guiReducer(state.gui, action),
//     status: statusReducer(state.status, action),
//   };
// }

const rootReducer = combineReducers({
  config: persistReducer(persistConfig, confReducer),
  auth: authReducer,
  apiconfigs: apiconfigsReducer,
  apitemplates: apitemplatesReducer,
  apisettings: apisettingsReducer,
  gui: guiReducer,
  status: statusReducer,
  healthcheck: healthcheckReducer,
});

export default rootReducer;
