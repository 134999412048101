/* jshint esversion: 9 */
import React, { Component } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DoneIcon from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { removeFilter } from '../../actions/removeFilter';
import FilterItem from '../filteritem';

import { updateFilter } from '../../actions/updateFilter';
import {
  GuiToolbarChange,
  GuiToolbarAddFilterPrepare,
  GuiToolbarAddFilterDone,
} from '../../actions/gui';

const styles = {
  root: {
    flexGrow: 1,
  },
};

const mapStateToProps = (state) => ({
  configRaw: state.config.configRaw,
  configFiltered: state.config.configFiltered,
  activeFilters: state.config.activeFilters,
  APIConfigs: state.apiconfigs,
  GUI: state.gui,
  auth: state.auth,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeFilter,
      GuiToolbarChange,
      GuiToolbarAddFilterPrepare,
      GuiToolbarAddFilterDone,
      updateFilter,
    },
    dispatch,
  );
}

const style = {
  background: 'rgb(60,60,65)',
  paddingLeft: '10px',
  paddingRight: '10px',
};

const buttonStyle = {
  minWidth: 30,
  padding: '8px 8px',
};

class FilterBar extends Component {
  constructor(props) {
    super(props);

    this.renderFilters = this.renderFilters.bind(this);
    this.renderAddFilter = this.renderAddFilter.bind(this);
    this.onRemoveFilter = this.onRemoveFilter.bind(this);
    this.onFilterUpdated = this.onFilterUpdated.bind(this);
    this.onFilterRemoved = this.onFilterRemoved.bind(this);
  }

  onFilterUpdated(e) {
    const {
      activeFilters,
      GuiToolbarAddFilterDone: GuiToolbarAddFilterDoneAlt,
      updateFilter: updateFilterAlt,
    } = this.props;
    const newfilters = Object.assign([], activeFilters);

    newfilters[e.index] = e.filter;
    GuiToolbarAddFilterDoneAlt(e);
    updateFilterAlt({ replace: true, newfilter: newfilters });
  }

  onFilterRemoved(e) {
    const {
      activeFilters,
      GuiToolbarAddFilterDone: GuiToolbarAddFilterDoneAlt,
      updateFilter: updateFilterAlt,
    } = this.props;
    const newfilters = Object.assign([], activeFilters);

    newfilters.splice(e.index, 1);
    GuiToolbarAddFilterDoneAlt(e.filter);
    updateFilterAlt({ replace: true, newfilter: newfilters });
  }

  onRemoveFilter(filterindex) {
    const { removeFilter: removeFilterAlt } = this.props;

    removeFilterAlt(filterindex);
  }

  addFilterButton = (e) => {
    const { GuiToolbarAddFilterPrepare: GuiToolbarAddFilterPrepareAlt } =
      this.props;

    e.preventDefault();
    GuiToolbarAddFilterPrepareAlt('');

    return 0;
  };

  addFilterDone = (e) => {
    const {
      GuiToolbarAddFilterDone: GuiToolbarAddFilterDoneAlt,
      updateFilter: updateFilterAlt,
    } = this.props;

    e.preventDefault();
    const newfilterfield = document.getElementById('addfiltermenu');
    const newfilter = newfilterfield.value;

    if (newfilter.indexOf(':') > 0) {
      const f = {};

      f.filtertype = newfilter.split(':')[0];
      f.filtervalue = newfilter.split(':')[1];

      GuiToolbarAddFilterDoneAlt(f);

      updateFilterAlt({ replace: false, newfilter: f });
    } else {
      const f = {};

      f.filtertype = 'text';
      f.filtervalue = newfilter;
      GuiToolbarAddFilterDoneAlt(f);

      updateFilterAlt({ replace: false, newfilter: f });
    }

    return 0;
  };

  menuFilter() {
    return (
      <div className="activeMenu fleft">
        <div className="filters">
          <span>Filters: </span>
          {this.renderFilters()}
          {this.renderAddFilter()}
        </div>
      </div>
    );
  }

  renderAddFilter() {
    const { GUI } = this.props;
    const toolbarInputStyle = {
      // backgroundColor: '#fff',
      color: '#000',
      display: 'inline',
      padding: 0,
      border: '0px',
      borderRadius: '3px',
      borderBottom: '0px',
      margin: '0px',
      paddingLeft: '5px',
      transition: 'none',
      height: '30px',
      lineHeight: '30px',
      width: 'auto',
      float: 'left',
      minWidth: 180,
    };

    if (GUI.addFilterPreparing) {
      return (
        <span className="filter-tag-edit">
          <input
            autoFocus
            id="addfiltermenu"
            className="filter-tag-input"
            type="text"
            placeholder="Type to search"
            defaultValue=""
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.addFilterDone(ev);
              }
            }}
            style={toolbarInputStyle}
          />

          <a
            className="i"
            aria-label="Done"
            href="#"
            onClick={this.addFilterDone}
          >
            <DoneIcon color="primary" />
          </a>
        </span>
      );
    }

    return (
      <Button style={buttonStyle} onClick={this.addFilterButton}>
        <AddBoxIcon />
      </Button>
    );
  }

  renderFilters() {
    const { activeFilters } = this.props;
    let content;

    if (activeFilters && activeFilters.length > 0) {
      content = (
        <div>
          {activeFilters.map((filter, index) => (
            <FilterItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              filter={filter}
              filterindex={index}
              onFilterUpdated={this.onFilterUpdated}
              onFilterRemoved={this.onFilterRemoved}
            />
          ))}
        </div>
      );
    } else {
      content = <div />;
    }

    return content;
  }

  render() {
    const activeMenu = this.menuFilter();

    return (
      <div>
        <Toolbar style={style} variant="dense">
          {activeMenu}
        </Toolbar>
      </div>
    );
  }
}
FilterBar.propTypes = {
  // classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FilterBar),
);
