/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-control-regex */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { UpdateRegisterUsername } from '../../actions/gui';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ UpdateRegisterUsername }, dispatch);
}
const mapStateToProps = (state) => ({
  gui: state.gui,
});

const styles = () => ({
  root: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    padding: 15,
    textAlign: 'center',
    background: '#22252a',
    opacity: '0.90',
    borderRadius: 4,
    margin: '5px auto',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  forminput: {
    // color: 'black',
  },
  arrowfont: {
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  leftalign: {
    textAlign: 'left',
  },
  tableicon: {
    fontSize: '1.1rem',
  },
});

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      gname: '',
      mail: '',
      pass: '',
      pass2: '',
      alert: {
        msg: 'Welcome aboard. All fields are required. ',
        status: 'welcome',
      },
    };
  }

  fnameChange = (e) => {
    this.setState({ fname: e.target.value });
  };

  gnameChange = (e) => {
    this.setState({ gname: e.target.value });
  };

  mailChange = (e) => {
    this.setState({ mail: e.target.value });
  };

  passChange = (e) => {
    this.setState({ pass: e.target.value });
  };

  pass2Change = (e) => {
    this.setState({ pass2: e.target.value });
  };

  isMail = (param) => {
    const str =
      /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

    return str.test(param);
  };

  signUp = (event) => {
    const { fname, gname, mail, pass, pass2 } = this.state;
    const { history, UpdateRegisterUsername: UpdateRegisterUsernameAlt } =
      this.props;

    event.preventDefault();

    if (!fname) {
      this.setState({
        alert: { msg: 'None of the fields can be empty', status: 'alert' },
      });
    }
    if (!gname) {
      this.setState({
        alert: { msg: 'None of the fields can be empty', status: 'alert' },
      });
    } else if (!mail) {
      this.setState({
        alert: { msg: 'Your e-mail adress is needed', status: 'alert' },
      });
    } else if (!this.isMail(mail)) {
      this.setState({
        alert: { msg: 'The e-mail you typed in is not valid', status: 'alert' },
      });
    } else if (!pass) {
      this.setState({
        alert: { msg: "Password can't be blank", status: 'alert' },
      });
    } else if (!pass2) {
      this.setState({
        alert: { msg: 'Please type your password twice', status: 'alert' },
      });
    } else if (pass !== pass2) {
      this.setState({
        alert: { msg: "Passwords don't match", status: 'alert' },
      });
    } else {
      const familyName = fname;

      const givenName = gname;
      const password = pass;
      const email = mail;
      const username = email;

      Auth.signUp({
        username,
        password,
        attributes: {
          family_name: familyName,
          given_name: givenName,
          email,
        },
      })
        .then(() => {
          UpdateRegisterUsernameAlt(email);
          document.getElementById('form-inputs').innerHTML = '';
          // this.setState({
          //   alert: {
          //     msg: 'You are registered. Please check your mailbox in order to validate your account.',
          //   },
          // });
          history.push('/register/confirm');
        })
        .catch((err) =>
          this.setState({ alert: { msg: err.message, status: 'alert' } }),
        );
    }
  };

  // googleSignUp = () => {
  //   window.location.href = process.env.REACT_APP_OAUTH_GOOGLEURL;
  // };

  render() {
    const { classes } = this.props;
    const { alert } = this.state;

    return (
      <Container className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.arrowfont} gutterBottom>
            Register
          </Typography>

          <p className={alert.status}>{alert.msg}.</p>

          <form id="form-inputs" onSubmit={this.signUp}>
            <Grid container spacing={3} style={{ marginTop: '40px' }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  className={classes.forminput}
                  id="firstname"
                  label="First Name"
                  fullWidth
                  onChange={this.gnameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  className={classes.forminput}
                  id="familyname"
                  label="Family Name"
                  fullWidth
                  onChange={this.fnameChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  className={classes.forminput}
                  label="Email Address"
                  fullWidth
                  onChange={this.mailChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="password"
                  required
                  className={classes.forminput}
                  id="password"
                  label="Password"
                  fullWidth
                  onChange={this.passChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="password"
                  required
                  className={classes.forminput}
                  id="password2"
                  label="Password (Confirm)"
                  fullWidth
                  onChange={this.pass2Change}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Proceed
                </Button>
              </Grid>
              <Grid item xs={12}>
                <a
                  href="/#login"
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  Already have an account ?
                </a>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    ); // return end
  } // render end
} // class RegisterPage end
RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPage)),
);
