import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getConfig } from 'xpand-parser-netscaler';
import { ParserDone, ParserError, ParserStart } from './actions/parser';

const Context = createContext();

let worker;

const useParser = (onError, onDone) => {
  const config = getConfig();

  useEffect(() => {
    worker = new Worker('parser.worker.js');

    return () => {
      if (worker) {
        worker.terminate();
        // $FlowFixMe
        worker = null;
      }
    };
  });

  const parse = (fileName, payload) => {
    if (!worker) {
      onError(new Error('Worker is not exist'));

      return;
    }

    // analyze here
    worker.postMessage({
      fileName,
      payload,
      config,
    });
    // $FlowFixMe
    worker.onerror = (err) => {
      onError(err);
    };
    // $FlowFixMe
    worker.onmessage = (e) => {
      // $FlowFixMe
      onDone(e.data.payload);
    };
  };

  return [parse];
};

function ParserContext(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const onError = (err) => {
    dispatch(ParserError(err));
  };
  const onSuccess = (e) => {
    dispatch(ParserDone(e));
  };
  const [parse] = useParser(onError, onSuccess);
  const useParse = (fileName, file) => {
    dispatch(ParserStart(file));
    parse(fileName, file);
  };

  return (
    <Context.Provider value={{ parse: useParse }}>{children}</Context.Provider>
  );
}

function withParser(Component) {
  return function WrappedComponent(props) {
    const contextData = useContext(Context);

    return <Component {...props} {...contextData} />;
  };
}

export { ParserContext, withParser };
