/* eslint-disable no-console */
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PublishIcon from '@material-ui/icons/Publish';

import { addTemplate } from '../../../actions/healthcheck/addTemplate';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addTemplate }, dispatch);
}

const mapStateToProps = () => ({});

// const baseStyle = {
//   flex: 1,
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   padding: '20px',
//   borderWidth: 2,
//   borderRadius: 2,
//   borderColor: '#eeeeee',
//   borderStyle: 'dashed',
//   backgroundColor: '#fafafa',
//   color: '#bdbdbd',
//   outline: 'none',
//   transition: 'border .24s ease-in-out',
// };

// const activeStyle = {
//   borderColor: '#2196f3',
// };

// const acceptStyle = {
//   borderColor: '#00e676',
// };

// const rejectStyle = {
//   borderColor: '#ff1744',
// };

class DropTemplate extends Component {
  onDrop = (acceptedFiles) => {
    const { addTemplate: addTemplateFn } = this.props;

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('File reading was aborted');
      reader.onerror = () => console.log('File reading error');
      reader.onload = () => {
        const text = reader.result;
        const newBlob = new Blob([text], { type: file.type });

        addTemplateFn({ text: newBlob, file: file.name });
        console.log(file.name);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  render() {
    return (
      <div className="container">
        <Dropzone className="healthcheckdropzone" onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                className="healthcheckdropzone"
                {...getRootProps({ className: 'healthcheckdropzone' })}
              >
                <input {...getInputProps()} />
                <p>Drag &apos;n drop Input .docx template</p>
                <div className="healthcheckdropzone-inner">
                  {/* <Icon large>file_upload</Icon> */}
                  <PublishIcon style={{ fontSize: '4rem' }} />
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DropTemplate);
