import { event } from '../../utils/ga-utils';

export const PARSER_START = 'PARSER_START';
export const PARSER_DONE = 'PARSER_DONE';
export const PARSER_ERROR = 'PARSER_ERROR';

export function ParserStart(payload) {
  return {
    type: PARSER_START,
    payload,
  };
}

export function ParserDone(payload) {
  return {
    type: PARSER_DONE,
    payload,
  };
}

export function ParserError(payload) {
  event('Error', 'ParseError', payload);

  return {
    type: PARSER_ERROR,
    payload,
  };
}
