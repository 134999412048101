/* jshint esversion: 9 */
import { ConfigParser, getConfig } from 'xpand-parser-netscaler';
import {
  FETCH_CONF_START,
  FETCH_CONF_END,
  EXPORT_CONF,
  EXPORT_JSON,
  EXPORT_PDF,
} from '../../actions/fetchConf';
import { UPDATE_FILTER } from '../../actions/updateFilter';
import { REMOVE_FILTER } from '../../actions/removeFilter';
import {
  SHAREDSNIPPET_SAVESTART,
  SHAREDSNIPPET_LOADERROR,
  SHAREDSNIPPET_LOADED,
  SHAREDSNIPPET_LOADSTART,
} from '../../actions/ApiSharedSnippet';

import { SHOW_ANALYSIS } from '../../actions/showAnalysis';
import { SET_ACTIVE_LINE } from '../../actions/setActiveLine';
import { CONFIG_LOADSTART, CONFIG_LOADED } from '../../actions/ApiConfig';
// import { ADD_PARSECONFIG } from '../../actions/addParseConfig';
import { PARSER_START, PARSER_DONE, PARSER_ERROR } from '../../actions/parser';

import ConfigExport from './ConfigExport';

const parseConfig = getConfig();

const initialState = {
  configRaw: '',
  configParsed: {},
  configFiltered: {},
  activeFilters: [],
  isConfigFetching: false,
  currentActivity: '',
  activeShareSnippetUrl: '',
  configLoadError: false,
  configLoadErrorDetail: '',
  analysisItems: [],
  analysisVisible: false,
  analysisEnabled: true,
  activeLine: null,
  stats: '',
  parseConfig: null,
};

const configParser = new ConfigParser();

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONF_START: {
      return {
        ...state,
        isConfigFetching: true,
        configParsed: {},
        configFiltered: {},
        analysisItems: [],
        analysisVisible: false,
        analysisEnabled: true,
        configLoadError: false,
        configLoadErrorDetail: '',
        activeFilters: [],
        stats: {},
        activeShareSnippetUrl: '',
      };
    }
    case SHOW_ANALYSIS: {
      return {
        ...state,
        analysisVisible: true,
      };
    }
    case SET_ACTIVE_LINE: {
      return {
        ...state,
        activeLine: action.payload,
      };
    }
    case FETCH_CONF_END: {
      return { ...state, isConfigFetching: false };
    }
    // case ADD_PARSECONFIG: {
    //   return {
    //     ...state,
    //     parseConfig: action.payload.data,
    //   };
    // }
    case CONFIG_LOADSTART: {
      const filtered = { ...state.configParsed };

      return {
        ...state,
        activeFilters: [],
        configFiltered: filtered,
        activeShareSnippetUrl: '',
      };
    }
    case EXPORT_CONF: {
      ConfigExport.export(state.configFiltered);

      return state;
    }
    case EXPORT_JSON: {
      ConfigExport.exportJson(state.configParsed, state.analysisItems);

      return state;
    }
    case EXPORT_PDF: {
      ConfigExport.exportPdf2(action.payload);

      return state;
    }
    case SHAREDSNIPPET_SAVESTART: {
      return {
        ...state,
        activeShareSnippetUrl:
          process.env.REACT_APP_SHAREDSNIPPETURL +
          action.payload.sharedSnippetId,
      };
    }
    case SHAREDSNIPPET_LOADSTART: {
      return {
        ...state,
        configLoadError: false,
        configLoadErrorDetail: '',
      };
    }
    case SHAREDSNIPPET_LOADERROR: {
      return {
        ...state,
        configLoadError: true,
        configLoadErrorDetail: action.payload,
      };
    }
    case SHAREDSNIPPET_LOADED: {
      let { shareDisableAnalysis } = action.payload;

      if (typeof shareDisableAnalysis !== 'boolean') {
        shareDisableAnalysis = false;
      }
      // eslint-disable-next-line no-console
      console.log(
        `shared snippet loaded, analysis = ${shareDisableAnalysis} and payload is: ${JSON.stringify(
          action.payload,
        )}`,
      );

      return {
        ...state,
        configLoadError: false,
        configLoadErrorDetail: '',
        analysisEnabled: !shareDisableAnalysis,
      };
    }
    case PARSER_START: {
      return {
        ...state,
        configRaw: action.payload,
        isConfigFetching: false,
        configLoadError: false,
        configLoadErrorDetail: '',
      };
    }

    case PARSER_DONE: {
      return {
        ...state,
        isConfigFetching: false,
        configParsed: action.payload.configParsed,
        configFiltered: action.payload.configParsed,
        analysisItems: action.payload.analysisItems,
        stats: action.payload.stats,
        activeShareSnippetUrl: '',
        fileName: action.payload.fileName,
      };
    }

    case PARSER_ERROR: {
      return {
        ...state,
        isConfigFetching: false,
        configParsed: {},
        configFiltered: {},
        analysisItems: [],
        stats: '',
        activeShareSnippetUrl: '',
      };
    }

    //  case LOAD_CONF: {

    // change raw config file based on file upload or db retrieve
    // parse config

    // Clean out sensitive information before uploading
    //         var t0 = performance.now();
    //         var parsed = ConfigParser.parse(action.payload, state.parseConfig);
    //         var t1 = performance.now();

    //         console.log("Parser took " + (t1 - t0).toFixed(0) + " milliseconds.");
    //         var filtered = Object.assign({},parsed);
    //       //  for(var i = 0; i < state.activeFilters.length; i++){
    //       //      filtered = ConfigParser.applyFilter(filtered, state.activeFilters[i], state.parseConfig);
    //      //   }
    //    //     var t2 = performance.now();
    //     //    console.log("Filter took " + (t2 - t1).toFixed(0) + " milliseconds.");
    //   //      if(!parsed.errors){
    //   //          var analysisresults = ConfigParser.analyze(parsed, state.parseConfig);
    //    //         var stats = ConfigParser.stats(parsed, action.payload);
    //    //         var t3 = performance.now();
    //    //         console.log("Analysis took " + (t3 - t2).toFixed(0) + " milliseconds.");
    //    //     } else {

    //   //      }

    //         return {...state,
    //             configRaw : action.payload,
    //             configParsed : parsed,
    //             configFiltered : filtered,
    //             isConfigFetching: false,
    //             analysisItems : analysisresults,
    //             stats: stats,
    //          }

    //   }
    case CONFIG_LOADED: {
      const filtered = { ...state.configParsed };

      return { ...state, configFiltered: filtered, activeFilters: [] };
    }

    case UPDATE_FILTER: {
      let arrReturn = [];

      if (!action.payload) {
        arrReturn = Object.assign([], state.activeFilters);
      } else {
        if (!action.payload.replace) {
          arrReturn = Object.assign([], state.activeFilters);
        }
        if (Array.isArray(action.payload.newfilter)) {
          arrReturn = arrReturn.concat(action.payload.newfilter);
        } else {
          arrReturn.push(action.payload.newfilter);
        }
      }
      // todo : validate incoming payload is correct filter

      let filtered = { ...state.configParsed };

      const t0 = performance.now();

      for (let i = 0; i < arrReturn.length; i += 1) {
        filtered = configParser.applyFilter(
          filtered,
          arrReturn[i],
          parseConfig,
        );
      }
      const t1 = performance.now();

      console.info(`Filter took ${(t1 - t0).toFixed(0)} milliseconds.`);
      // var filtered = ConfigParser.applyFilter(state.configParsed, arrReturn);

      return {
        ...state,
        configFiltered: filtered,
        activeFilters: arrReturn,
        activeShareSnippetUrl: '',
        analysisVisible: false,
      };
    }

    case REMOVE_FILTER: {
      // filter configParsed into configFiltered
      const arrReturn = Object.assign([], state.activeFilters);
      const index = arrReturn.indexOf(action.payload);

      if (index > -1) {
        const t0 = performance.now();
        const filtered = configParser.applyFilter(
          state.configParsed,
          arrReturn,
          parseConfig,
        );
        const t1 = performance.now();

        console.info(`Filter took ${(t1 - t0).toFixed(0)} milliseconds.`);

        return {
          ...state,
          configFiltered: filtered,
          activeFilters: arrReturn,
          activeShareSnippetUrl: '',
        };
      }
      break;
    }
    default:
      return state;
  }

  return state;
};
