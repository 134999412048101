/* eslint-disable react/require-default-props */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    flexGrow: 1,
  },
};

class ShareStep2Dialog extends React.Component {
  handleClose = () => {
    const { onClose: onCloseAlt } = this.props;

    onCloseAlt(true);
  };

  render() {
    const { activeShareSnippetUrl, ...other } = this.props;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="Share Configuration Snippet"
        fullWidth={false}
        {...other}
      >
        <DialogTitle id="form-dialog-title">Share Configuration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Copy the following link to share this configuration snippet publicly
            (Note: only visible lines will be shared)
          </DialogContentText>
          <TextField
            // eslint-disable-next-line react/no-string-refs
            ref="shareURL"
            autoFocus
            margin="dense"
            id="configshare-url"
            label="Link"
            value={activeShareSnippetUrl}
            variant="filled"
            fullWidth
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
ShareStep2Dialog.propTypes = {
  onClose: PropTypes.func,
};

export default withStyles(styles)(ShareStep2Dialog);
