import React from 'react';
import {
  Text,
  Link,
  StyleSheet,
  // $FlowFixMe
} from '@react-pdf/renderer';

const HTML_ELEMENT_TYPE = {
  TEXT: '#text',
  HYPERLINK: 'A',
  LINE_BREAK: '<br />',
};

const styles = StyleSheet.create({
  heading2: {
    fontSize: '14px',
    fontFamily: 'Arial',
    color: '#000000',
    marginBottom: '10px',
  },
  defaultParagrpah: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    color: '#000000',
    marginBottom: '10px',
  },
  boldParagrpah: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    fontWeight: 700,
    color: '#000000',
    marginBottom: '5px',
  },
  listParagrpah1: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    color: '#000000',
    margin: '0 0 5px 24px',
  },
  linkParagrpah: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    color: '#5F5F5F',
    marginBottom: '5px',
  },
  criticalBoldParagrpah: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    fontWeight: 700,
    color: '#C92428',
  },
  majorBoldParagrpah: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    fontWeight: 700,
    color: '#FFA497',
  },
  mediumBoldParagrpah: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    fontWeight: 700,
    color: '#FFD674',
  },
  minorBoldParagrpah: {
    fontSize: '11px',
    fontFamily: 'Arial',
    lineHeight: '1.5',
    fontWeight: 700,
    color: '#40A3E0',
  },
});

const parseHtmlToPdfElements = (description) => {
  const splittedStringTags = description.split(HTML_ELEMENT_TYPE.LINE_BREAK);
  const pdfElements = [];

  splittedStringTags.forEach((stringPart, index) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(stringPart, 'text/html');
    // $FlowFixMe
    const htmlElements = parsedHtml.body.childNodes;
    const pdfElementsKey = `pdfElements_${index}`;
    const partPdfElements = [];

    htmlElements.forEach((htmlElement, htmlElIndex) => {
      const pdfElementKey = `pdfElement_${htmlElIndex}`;

      if (htmlElement.nodeName === HTML_ELEMENT_TYPE.TEXT) {
        partPdfElements.push(
          <Text key={pdfElementKey} style={styles.defaultParagrpah}>
            {htmlElement.nodeValue}
          </Text>,
        );
      }
      if (htmlElement.nodeName === HTML_ELEMENT_TYPE.HYPERLINK) {
        partPdfElements.push(
          <Link
            key={pdfElementKey}
            style={styles.linkParagrpah}
            // $FlowFixMe
            src={htmlElement.href}
          >
            {htmlElement.innerText}
          </Link>,
        );
      }
    });

    pdfElements.push(
      <Text
        key={pdfElementsKey}
        style={[styles.listParagrpah1, { marginBottom: 0 }]}
      >
        {partPdfElements}
      </Text>,
    );
  });

  return pdfElements;
};

const buildAnalysisItemsElements = (
  items,
  itemsTypeClassName,
  itemsTypeLabel,
) =>
  items.map((item, index) => {
    const { rule } = item;
    const { name, description } = rule;
    const pdfDescriptionElements = parseHtmlToPdfElements(description || '');
    const key = `${name}_${index}`;

    return (
      <React.Fragment key={key}>
        <Text style={[styles.listParagrpah1, { margin: '10px 0 0 24px' }]}>
          • <Text style={styles[itemsTypeClassName]}>{itemsTypeLabel}</Text>
          <Text style={styles.boldParagrpah}>: {name}</Text>
        </Text>
        {pdfDescriptionElements}
      </React.Fragment>
    );
  });

const HealthCheckFindings = (props) => {
  const { healthCheckFindings } = props;

  return healthCheckFindings.map((finding, index) => {
    const { analysis, instanceName, adcFormFactor, adcLicense } = finding;
    const { stats, analysisItems, filterByMatched } = analysis;
    const {
      firmware,
      ha,
      linecount,
      vipcount,
      appexpertcount,
      appfwcount,
      mode,
      features,
      partitions,
      certkeys,
    } = stats;
    const key = `${instanceName}_${index}`;
    const criticalItems = [];
    const majorItems = [];
    const mediumItems = [];
    const minorItems = [];

    if (analysisItems) {
      analysisItems.forEach((item) => {
        const shouldAddItem = filterByMatched ? item.matched : true;

        if (shouldAddItem) {
          if (item.rule.severity === 1) {
            criticalItems.push(item);
          } else if (item.rule.severity === 2) {
            majorItems.push(item);
          } else if (item.rule.severity === 3) {
            mediumItems.push(item);
          } else if (item.rule.severity === 4 || item.rule.severity === 5) {
            minorItems.push(item);
          }
        }
      });
    }

    return (
      <React.Fragment key={key}>
        <Text style={[styles.heading2, { marginTop: '10px' }]}>
          2.{index + 1}. Instance report for {instanceName}
        </Text>
        <Text style={styles.defaultParagrpah}>Instance Properties:</Text>
        {adcFormFactor ? (
          <Text style={styles.listParagrpah1}>
            • Form Factor:{' '}
            <Text style={styles.boldParagrpah}>{adcFormFactor}</Text>
          </Text>
        ) : null}
        {adcLicense ? (
          <Text style={styles.listParagrpah1}>
            • License: <Text style={styles.boldParagrpah}>{adcLicense}</Text>
          </Text>
        ) : null}
        <Text style={styles.listParagrpah1}>
          • Instance Firmware version:{' '}
          <Text style={styles.boldParagrpah}>{firmware}</Text>
        </Text>
        <Text style={styles.listParagrpah1}>
          • High-Availability configured:{' '}
          <Text style={styles.boldParagrpah}>{ha ? 'YES' : 'NO'}</Text>
        </Text>
        <Text style={styles.listParagrpah1}>
          • Modes: <Text style={styles.boldParagrpah}>{mode}</Text>
        </Text>
        <Text style={[styles.listParagrpah1, { marginBottom: '10px' }]}>
          • Features: <Text style={styles.boldParagrpah}>{features}</Text>
        </Text>
        <Text style={[styles.defaultParagrpah, { marginBottom: '10px' }]}>Statistics:</Text>
        <Text style={styles.listParagrpah1}>
          • Line Count: <Text style={styles.boldParagrpah}>{linecount}</Text>
        </Text>
        <Text style={styles.listParagrpah1}>
          • VIP Count: <Text style={styles.boldParagrpah}>{vipcount}</Text>
        </Text>
        <Text style={styles.listParagrpah1}>
          • App Expert lines:{' '}
          <Text style={styles.boldParagrpah}>{appexpertcount}</Text>
        </Text>
        <Text style={styles.listParagrpah1}>
          • AppFW lines: <Text style={styles.boldParagrpah}>{appfwcount}</Text>
        </Text>
        <Text style={styles.listParagrpah1}>
          • Admin Partitions:{' '}
          <Text style={styles.boldParagrpah}>{partitions}</Text>
        </Text>
        <Text style={[styles.listParagrpah1, { marginBottom: '10px' }]}>
          • SSL Certs: <Text style={styles.boldParagrpah}>{certkeys}</Text>
        </Text>
        <Text style={[styles.defaultParagrpah, { marginBottom: '10px' }]}>
          Based on analysis of the configuration file the following findings
          have been discovered:
        </Text>
        {buildAnalysisItemsElements(
          criticalItems,
          'criticalBoldParagrpah',
          'Critical',
        )}
        {buildAnalysisItemsElements(majorItems, 'majorBoldParagrpah', 'Major')}
        {buildAnalysisItemsElements(
          mediumItems,
          'mediumBoldParagrpah',
          'Medium',
        )}
        {buildAnalysisItemsElements(minorItems, 'minorBoldParagrpah', 'Minor')}
      </React.Fragment>
    );
  });
};

export default HealthCheckFindings;
