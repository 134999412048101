/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty */
/* eslint-disable no-console */

import { TemplateHandler } from 'easy-template-x';
import { ADD_INPUT } from '../../actions/healthcheck/addInput';
import { ADD_TEMPLATE } from '../../actions/healthcheck/addTemplate';
import { GENERATE_DOC } from '../../actions/healthcheck/generateDoc';
import { CLEAR } from '../../actions/healthcheck/clear';
import {
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMERCONTACT,
} from '../../actions/healthcheck/inputForm';
import saveFile from './utils';

const initialState = {
  inputs: [],
  form: {
    customername: '',
    customercontact: '',
  },
  template: {
    file: '',
    text: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEMPLATE: {
      const newTemplate = {
        file: action.payload.file,
        text: action.payload.text,
      };

      return { ...state, template: newTemplate };
    }
    case ADD_INPUT: {
      let parsed = 'Unable to parse JSON';

      try {
        parsed = JSON.parse(action.payload.text);
      } catch {}
      const newInput = {
        filename: action.payload.file,
        json: parsed,
      };

      return { ...state, inputs: [...state.inputs, newInput] };
    }

    case UPDATE_CUSTOMER: {
      return {
        ...state,
        form: { ...state.form, customername: action.payload },
      };
    }

    case UPDATE_CUSTOMERCONTACT: {
      return {
        ...state,
        form: { ...state.form, customercontact: action.payload },
      };
    }
    case CLEAR: {
      return initialState;
    }

    case GENERATE_DOC: {
      console.log('Transforming input jsons');

      const { inputs } = state;
      const output = {};

      output.customername = state.form.customername;
      output.customerlogo = 'Logo url here';
      output.customercontact = state.form.customercontact;

      output.instances = [];

      const findings = [];

      for (const input of inputs) {
        output.instances.push(input.json);

        // summarize all findings
        const instanceFindings = input.json.findings;

        for (const instanceFinding of instanceFindings) {
          instanceFinding.te = '4h'; // dummy timing estimate for now
          if (instanceFinding.matched) {
            const instanceFindingIdentifier = instanceFinding.identifier;

            const found = findings.some(
              (element) => element.identifier === instanceFindingIdentifier,
            );

            if (!found) {
              const newFindingObj = {
                ...instanceFinding,
                instances: [input.json.instancename],
              };

              findings.push(newFindingObj);
            } else {
              // update exsting finding obj.
              const existingIndex = findings.findIndex(
                (element) => element.identifier === instanceFindingIdentifier,
              );

              findings[existingIndex].instances.push(input.json.instancename);
            }
          }
        }
      }
      output.findingsummary = findings;
      console.log(JSON.stringify(findings));

      console.log('Processing template');

      // fetch("/res/template.docx")
      //     .then(request => request.blob())
      //      .then(defaultTemplate => {
      const inputDataJson = output;
      const handler = new TemplateHandler();
      handler.process(state.template.text, inputDataJson).then((docx) => {
        console.log('saving output');
        saveFile('result.docx', docx);
      });

      //    });

      // const request = fetch("/res/template.docx");

      return { ...state };
    }

    default:
      return state;
  }
};
