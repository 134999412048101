/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

const mapStateToProps = (state) => ({
  auth: state.auth,
  gui: state.gui,
});

const styles = () => ({
  root: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    padding: 15,
    textAlign: 'center',
    background: '#22252a',
    opacity: '0.90',
    borderRadius: 4,
    margin: '5px auto',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  forminput: {
    // color: 'black',
  },
  arrowfont: {
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  leftalign: {
    textAlign: 'left',
  },
  tableicon: {
    fontSize: '1.1rem',
  },
});

class VerifDonePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: {
        msg: 'Successfully validated email address. Click the link below to login',
        status: '',
      },
    };
  }

  loginClick = (event) => {
    const { history } = this.props;

    event.preventDefault();
    history.push('/login');
  };

  render() {
    const { classes } = this.props;
    const { alert } = this.state;

    return (
      <Container className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.arrowfont} gutterBottom>
            Account validated
          </Typography>

          <p className={alert.status}>{alert.msg} </p>

          <form onSubmit={this.loginClick}>
            <Grid container spacing={3} style={{ marginTop: '40px' }}>
              <Grid item xs={12}>
                <Button type="submit" waves="light">
                  Log In
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    ); // return end
  } // render end
} // class end
VerifDonePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, null)(VerifDonePage)),
);
