/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers';

const createStoreWithMiddleware = applyMiddleware(
  ReduxPromise,
  thunk,
)(createStore);

const persistConfig = {
  key: 'root',
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
  blacklist: ['config'],
};

// const initialState = {
//   config: {
//     configRaw: '',
//     configParsed: {},
//     configFiltered: {},
//     activeFilters: [],
//     isConfigFetching: false,
//     currentActivity: '',
//     activeShareSnippetUrl: '',
//     configLoadError: false,
//     configLoadErrorDetail: '',
//     analysisItems: [],
//     analysisVisible: false,
//     activeLine: null,
//     stats: '',
//     parseConfig: null,
//   },
//   auth: {
//     isAuthenticated: false,
//     isAuthenticating: true,
//     session: {},
//     username: null,
//     attributes: null,
//     jwt: null,
//   },
//   apiconfigs: {
//     isConfigsRetrieving: false,
//     configs: [],
//     isConfigSavePreparing: false,
//     isConfigSaved: false,
//     activeconfigid: null,
//     isConfigLoaded: false,
//     isError: false,
//     errorMessage: '',
//   },
//   apitemplates: {
//     isTemplatesRetrieving: false,
//     templates: [],
//     isTemplateSavePreparing: false,
//     isTemplateActivity: false,
//     isTemplateSaved: false,
//     activetemplateid: null,
//     isTemplateLoaded: false,
//   },
//   apisettings: {
//     isSettingsRetrieving: false,
//     settings: [],

//     isError: false,
//     errorMessage: '',
//   },
//   gui: {
//     activeToolbar: 'filter',
//     addFilterPreparing: false,
//   },
// };

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStoreWithMiddleware(
  persistedReducer,
  // initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export const persistor = persistStore(store);

export const resetStore = async () => {
  await persistor.purge();
  store.dispatch(resetStore());
  await persistor.flush();
};

export const flushStore = async () => {
  await persistor.flush();
};
