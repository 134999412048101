/* eslint-disable default-case */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-extend-native */
/* eslint-disable no-console */
/* jshint esversion: 9 */
import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
// Components
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Auth, Hub } from 'aws-amplify';
import TopBar from './components/topbar';
import Footer from './components/footer';
// Pages
import HomePage from './pages/home';
import LoginPage from './pages/login';
import PasswordResetPage from './pages/passwordreset';
import PasswordResetConfirmPage from './pages/passwordreset/confirm';
import RegisterPage from './pages/register';
import VerifDonePage from './pages/register/verificatedone';
import VerifPage from './pages/register/verificate';
import ConfigsPage from './pages/configs';
import SnippetPage from './pages/snippet';
import SharedSnippetPage from './pages/sharedsnippet';
import ProfilePage from './pages/profile';
import AboutPage from './pages/about';
import WhatsnewPage from './pages/whatsnew';
import HealthCheckPage from './pages/healthcheck';

import { checkLogin } from './actions/checkLogin';
// import { addParseConfig } from './actions/addParseConfig';
import { authLogout } from './actions/authLogout';
import { event as GAEvent } from './utils/ga-utils';

// const axios = require('axios');

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      checkLogin,
      authLogout,
    },
    dispatch,
  );

const mapStateToProps = (state) => ({
  auth: state.auth,
  currentPath: state.gui.currentPath,
});

// Prototype to add case insensitive property getter to object type
Object.defineProperty(Object.prototype, 'getProp', {
  value(prop) {
    let key;
    const self = this;

    for (key in self) {
      if (key.toLowerCase() === prop.toLowerCase()) {
        return self[key];
      }
    }

    return undefined;
  },
  enumerable: false,
});

Hub.listen('auth', ({ payload: { event, data } }) => {
  switch (event) {
    case 'signIn':
      // signin event
      break;
    case 'customOAuthState':
      // after redirect to Google Auth app is reloaded
      // this contains custom state, which was original path when initiating logon
      if (
        data !== '/' &&
        data !== '/#/' &&
        data.toLowerCase().indexOf('register') === -1
      ) {
        window.location.href = `/#${data}`;
      }
      break;
  }
});

class App extends Component {
  // constructor(props){
  //   super(props);
  // }
  componentDidMount() {
    // const thisI = this;
    const { checkLogin: checkLoginAlt, authLogout: authLogoutAlt } = this.props;
    // this.serverRequest = axios
    //   .get(`${process.env.REACT_APP_COMMON_URL}/config/parser.json`)
    //   .then((result) => {
    //     thisI.props.addParseConfig(result);
    //   });

    Auth.currentAuthenticatedUser().then(
      (result) => {
        GAEvent('User', 'SignIn', result.attributes.email.replace('@', '_'));
        checkLoginAlt(result.signInUserSession, result.attributes);
      },
      (error) => {
        console.log(error);
        authLogoutAlt(true);
      },
    );
  }

  // checkUser() {
  //   Auth.currentAuthenticatedUser().then((result) => {
  //     const { checkLogin: checkLoginAlt } = this.props;
  //     checkLoginAlt(result.signInUserSession, result.attributes);
  //     console.log(result.attributes);
  //     const profileComplete = result.attributes['custom:profileComplete'];
  //     if (profileComplete) {
  //       console.log(`profileComplete: ${profileComplete}`);
  //       const { settings } = this.props;
  //       if (profileComplete !== settings.profileversion)
  //         console.log('Profile not complete, redirecting.. ');
  //     } else {
  //       console.log('Profile not complete, redirecting.. ');
  //     }
  //   });
  // }

  render() {
    const { auth, settings } = this.props;

    if (auth.isAuthenticated) {
      if (auth.attributes) {
        const profileComplete = auth.attributes['custom:profileComplete'];

        if (profileComplete) {
          console.log(`profileComplete: ${profileComplete}`);
          if (profileComplete !== settings.profileversion) {
            console.log('Profile not complete, redirecting.. ');

            return (
              <Router>
                <div className="main">
                  <TopBar />
                  <ProfilePage settings={settings} />
                  <Footer />
                </div>
              </Router>
            );
          }
        } else {
          console.log('Profile not complete, redirecting.. ');

          return (
            <Router>
              <div className="main">
                <TopBar />
                <ProfilePage settings={settings} />
                <Footer />
              </div>
            </Router>
          );
        }
      }
    }

    return (
      <Router>
        <div className="main">
          <Route component={TopBar} />
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <>
                  <HomePage props={props} />
                  <Footer props={props} />
                </>
              )}
            />
            <Route
              exact
              path="/login"
              render={() => (
                <>
                  <LoginPage />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/passwordresetconfirm"
              render={() => (
                <>
                  <PasswordResetConfirmPage />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/passwordreset"
              render={() => (
                <>
                  <PasswordResetPage />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/register"
              render={() => (
                <>
                  <RegisterPage />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/register/confirm"
              render={() => (
                <>
                  <VerifPage />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/register/done"
              render={() => (
                <>
                  <VerifDonePage />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/register/confirm/:code"
              render={() => (
                <>
                  <VerifPage />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/configs"
              render={(props) => (
                <>
                  <ConfigsPage props={props} />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/whatsnew"
              render={(props) => (
                <>
                  <WhatsnewPage props={props} />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/healthcheck"
              render={(props) => (
                <>
                  <HealthCheckPage props={props} />
                  <Footer />
                </>
              )}
            />
            <Route
              path="/(access_token|id_token)(.+)"
              render={(props) => (
                <>
                  <ConfigsPage props={props} />
                  <Footer />
                </>
              )}
            />
            <Route
              exact
              path="/profile"
              render={(props) => (
                <>
                  <ProfilePage settings={props.settings} />
                  <Footer />
                </>
              )}
            />
            <Route exact path="/snippet" component={SnippetPage} />
            <Route
              exact
              path="/about"
              render={() => (
                <>
                  <AboutPage />
                  <Footer />
                </>
              )}
            />
            <Route path="/:snippetid(.+)" component={SharedSnippetPage} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
