/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';
import { checkLogin } from '../../actions/checkLogin';
import { authLogout } from '../../actions/authLogout';
import { updateAttr } from '../../actions/updateAttr';
import { ApiSettingsFetch } from '../../actions/ApiSettings';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { checkLogin, authLogout, ApiSettingsFetch, updateAttr },
    dispatch,
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const styles = () => ({
  root: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    padding: 15,
    textAlign: 'center',
    background: '#22252a',
    opacity: '0.90',
    borderRadius: 4,
    margin: '5px auto',
    marginTop: '20px',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  forminput: {
    // color: 'black',
  },
  arrowfont: {
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  leftalign: {
    textAlign: 'left',
  },
});

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.inputGivenname = React.createRef();
    this.inputFamilyname = React.createRef();
    this.inputEmail = React.createRef();
    this.inputCompany = React.createRef();
    this.inputCountry = React.createRef();
    this.inputPhone = React.createRef();
    this.inputJobtitle = React.createRef();
    this.inputRole = React.createRef();
    this.inputPrivacy = React.createRef();
    this.inputSponsor = React.createRef();

    this.state = {
      errorMessage: {},
    };
  }

  handleSubmit = async (event) => {
    const { settings, auth, updateAttr: updateAttrAlt } = this.props;
    // Perform validation
    let isError = false;

    // given name
    if (this.inputGivenname.current.value.length < 2) {
      isError = true;
      this.setState({
        errorMessage: { givenname: 'Invalid given name' },
      });
    }
    // family name
    if (this.inputFamilyname.current.value.length < 2) {
      isError = true;
      this.setState({
        errorMessage: { familyname: 'Invalid family name' },
      });
    }
    // Company
    if (this.inputCompany.current.value.length < 2) {
      isError = true;
      this.setState({
        errorMessage: { company: 'Invalid Company' },
      });
    }
    // Job Title
    if (this.inputJobtitle.current.value.length < 3) {
      isError = true;
      this.setState({
        errorMessage: { jobtitle: 'Invalid Job Title' },
      });
    }
    // busPhone
    if (this.inputPhone.current.value.length < 6) {
      isError = true;
      this.setState({
        errorMessage: { busphone: 'Invalid Phone' },
      });
    }
    // country
    if (this.inputCountry.current.value.length < 2) {
      isError = true;
      this.setState({
        errorMessage: { country: 'Please select a country' },
      });
    }
    // role
    if (
      !this.inputRole.current.value ||
      this.inputRole.current.value.length < 2
    ) {
      isError = true;
      this.setState({
        errorMessage: { role: 'Please select a role' },
      });
    }
    // Checkboxes

    if (!this.inputPrivacy.current.checked) {
      isError = true;
      this.setState({
        errorMessage: { inputprivacy: 'Required' },
      });
    }
    if (!this.inputSponsor.current.checked) {
      isError = true;
      this.setState({
        errorMessage: { inputsponsor: 'Required' },
      });
    }

    if (!isError) {
      this.setState({
        errorMessage: {},
      });
      // Submit updated attributes back to Cognito..
      const attrib = {
        given_name: this.inputGivenname.current.value,
        family_name: this.inputFamilyname.current.value,
        'custom:profileComplete': settings.profileversion,
        'custom:jobtitle': this.inputJobtitle.current.value,
        'custom:role': this.inputRole.current.value,
        'custom:busPhone': this.inputPhone.current.value,
        'custom:country': this.inputCountry.current.value,
        'custom:company': this.inputCompany.current.value,
      };

      Auth.currentAuthenticatedUser().then((result) => {
        Auth.updateUserAttributes(result, attrib).then(() => {
          updateAttrAlt(attrib);

          // Submit form to Eloqua
          const newattrib = {
            ...attrib,
            email: auth.attributes.email,
          };

          delete newattrib['custom:profileComplete'];

          this.submitEloqua(newattrib);
        });
      });
    }
    event.preventDefault();
  };

  submitEloqua = async (attributes) => {
    const postUrl = 'https://s159500889.t.eloqua.com/e/f2';

    const params = {};

    params.elqFormName = '637474115014571902';
    params.elqSiteId = '159500889';
    params.elqCampaignId = '';

    params.firstName = attributes.given_name;
    params.lastName = attributes.family_name;
    params.company = attributes['custom:company'];
    params.emailAddress = attributes.email;
    params.busPhone = attributes['custom:busPhone'];
    params.country = attributes['custom:country'];
    params.jobTitle1 = attributes['custom:jobtitle'];
    params.dropdownMenu = attributes['custom:role'];

    const formBody = Object.keys(params)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&');

    await fetch(postUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: formBody,
    }).then((response) => {
      console.info(`updated eloqua: ${JSON.stringify(response)}`);
    });
  };

  render() {
    const { classes, auth } = this.props;
    const { errorMessage } = this.state;

    // Each page will render <Container> block at the root
    return (
      <Container className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.arrowfont} gutterBottom>
            My Profile
          </Typography>

          {auth.isAuthenticated && auth.attributes ? (
            <>
              <Typography
                variant="h6"
                className={classes.arrowfont}
                gutterBottom
              >
                please complete the following information:
              </Typography>

              <form onSubmit={this.handleSubmit}>
                {/* <form method="post" name="637454330156041449" action="https://s159500889.t.eloqua.com/e/f2" onSubmit={this.handleSubmit} id="form3670" className="elq-form"> */}
                {/* <input value="637454330156041449" type="hidden" name="elqFormName" />
                <input value="159500889" type="hidden" name="elqSiteId" />
                <input name="elqCampaignId" type="hidden" /> */}
                <Grid container spacing={3} style={{ marginTop: '40px' }}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="role" required>
                        I am a (Please choose)
                      </InputLabel>
                      <Select
                        error={!!errorMessage.role}
                        labelId="role"
                        onChange={function noRefCheck() {}}
                        inputRef={this.inputRole}
                        defaultValue={
                          auth.attributes['custom:role']
                            ? auth.attributes['custom:role']
                            : null
                        }
                        style={{ textAlign: 'left' }}
                      >
                        <FormHelperText>{errorMessage.role}</FormHelperText>
                        <MenuItem value="End User">Customer</MenuItem>
                        <MenuItem value="Reseller">Reseller</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} />
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={!!errorMessage.givenname}
                      required
                      className={classes.forminput}
                      id="firstname"
                      defaultValue={auth.attributes.given_name}
                      label="First Name"
                      fullWidth
                      inputRef={this.inputGivenname}
                      helperText={errorMessage.givenname}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={!!errorMessage.familyname}
                      required
                      className={classes.forminput}
                      label="Last Name"
                      fullWidth
                      defaultValue={auth.attributes.family_name}
                      inputRef={this.inputFamilyname}
                      helperText={errorMessage.familyname}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={!!errorMessage.email}
                      InputProps={{
                        readOnly: true,
                      }}
                      className={classes.forminput}
                      defaultValue={auth.attributes.email}
                      label="Email Address"
                      fullWidth
                      inputRef={this.inputEmail}
                      helperText={errorMessage.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={!!errorMessage.comnpany}
                      required
                      className={classes.forminput}
                      label="Company"
                      fullWidth
                      defaultValue={auth.attributes['custom:company'] || ''}
                      inputRef={this.inputCompany}
                      helperText={errorMessage.company}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={!!errorMessage.jobtitle}
                      required
                      className={classes.forminput}
                      label="Job Title"
                      fullWidth
                      defaultValue={auth.attributes['custom:jobtitle'] || ''}
                      inputRef={this.inputJobtitle}
                      helperText={errorMessage.jobtitle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={!!errorMessage.busphone}
                      required
                      className={classes.forminput}
                      label="Business Phone"
                      fullWidth
                      defaultValue={auth.attributes['custom:busPhone'] || ''}
                      inputRef={this.inputPhone}
                      helperText={errorMessage.busphone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="country" required>
                        Country
                      </InputLabel>
                      <Select
                        labelId="country"
                        onChange={function noRefCheck() {}}
                        inputRef={this.inputCountry}
                        defaultValue={auth.attributes['custom:country'] || ''}
                        style={{ textAlign: 'left' }}
                        error={!!errorMessage.country}
                      >
                        <MenuItem value="">-- Please Select --</MenuItem>
                        <MenuItem value="US">United States</MenuItem>
                        <MenuItem value="UK">United Kingdom</MenuItem>
                        <MenuItem value="AF">Afghanistan</MenuItem>
                        <MenuItem value="AX">Åland Islands</MenuItem>
                        <MenuItem value="AL">Albania</MenuItem>
                        <MenuItem value="DZ">Algeria</MenuItem>
                        <MenuItem value="AS">American Samoa</MenuItem>
                        <MenuItem value="AD">Andorra</MenuItem>
                        <MenuItem value="AO">Angola</MenuItem>
                        <MenuItem value="AI">Anguilla</MenuItem>
                        <MenuItem value="AQ">Antarctica</MenuItem>
                        <MenuItem value="AG">Antigua and Barbuda</MenuItem>
                        <MenuItem value="AR">Argentina</MenuItem>
                        <MenuItem value="AM">Armenia</MenuItem>
                        <MenuItem value="AW">Aruba</MenuItem>
                        <MenuItem value="AU">Australia</MenuItem>
                        <MenuItem value="AT">Austria</MenuItem>
                        <MenuItem value="AZ">Azerbaijan</MenuItem>
                        <MenuItem value="BS">Bahamas</MenuItem>
                        <MenuItem value="BH">Bahrain</MenuItem>
                        <MenuItem value="BD">Bangladesh</MenuItem>
                        <MenuItem value="BB">Barbados</MenuItem>
                        <MenuItem value="BY">Belarus</MenuItem>
                        <MenuItem value="BE">Belgium</MenuItem>
                        <MenuItem value="BZ">Belize</MenuItem>
                        <MenuItem value="BJ">Benin</MenuItem>
                        <MenuItem value="BM">Bermuda</MenuItem>
                        <MenuItem value="BT">Bhutan</MenuItem>
                        <MenuItem value="BO">Bolivia</MenuItem>
                        <MenuItem value="BA">Bosnia and Herzegovina</MenuItem>
                        <MenuItem value="BW">Botswana</MenuItem>
                        <MenuItem value="BV">Bouvet Island</MenuItem>
                        <MenuItem value="BR">Brazil</MenuItem>
                        <MenuItem value="IO">Brit/Indian Ocean Terr.</MenuItem>
                        <MenuItem value="BN">Brunei Darussalam</MenuItem>
                        <MenuItem value="BG">Bulgaria</MenuItem>
                        <MenuItem value="BF">Burkina Faso</MenuItem>
                        <MenuItem value="BI">Burundi</MenuItem>
                        <MenuItem value="KH">Cambodia</MenuItem>
                        <MenuItem value="CM">Cameroon</MenuItem>
                        <MenuItem value="CA">Canada</MenuItem>
                        <MenuItem value="CV">Cape Verde</MenuItem>
                        <MenuItem value="KY">Cayman Islands</MenuItem>
                        <MenuItem value="CF">Central African Republic</MenuItem>
                        <MenuItem value="TD">Chad</MenuItem>
                        <MenuItem value="CL">Chile</MenuItem>
                        <MenuItem value="CN">China</MenuItem>
                        <MenuItem value="CX">Christmas Island</MenuItem>
                        <MenuItem value="CC">Cocos (Keeling) Islands</MenuItem>
                        <MenuItem value="CO">Colombia</MenuItem>
                        <MenuItem value="KM">Comoros</MenuItem>
                        <MenuItem value="CG">Congo</MenuItem>
                        <MenuItem value="CD">
                          Congo, The Dem. Republic Of
                        </MenuItem>
                        <MenuItem value="CK">Cook Islands</MenuItem>
                        <MenuItem value="CR">Costa Rica</MenuItem>
                        <MenuItem value="CI">{"Côte d'Ivoire"}</MenuItem>
                        <MenuItem value="HR">Croatia</MenuItem>
                        <MenuItem value="CU">Cuba</MenuItem>
                        <MenuItem value="CY">Cyprus</MenuItem>
                        <MenuItem value="CZ">Czech Republic</MenuItem>
                        <MenuItem value="DK">Denmark</MenuItem>
                        <MenuItem value="DJ">Djibouti</MenuItem>
                        <MenuItem value="DM">Dominica</MenuItem>
                        <MenuItem value="DO">Dominican Republic</MenuItem>
                        <MenuItem value="EC">Ecuador</MenuItem>
                        <MenuItem value="EG">Egypt</MenuItem>
                        <MenuItem value="SV">El Salvador</MenuItem>
                        <MenuItem value="GQ">Equatorial Guinea</MenuItem>
                        <MenuItem value="ER">Eritrea</MenuItem>
                        <MenuItem value="EE">Estonia</MenuItem>
                        <MenuItem value="ET">Ethiopia</MenuItem>
                        <MenuItem value="FK">Falkland Islands</MenuItem>
                        <MenuItem value="FO">Faroe Islands</MenuItem>
                        <MenuItem value="FJ">Fiji</MenuItem>
                        <MenuItem value="FI">Finland</MenuItem>
                        <MenuItem value="FR">France</MenuItem>
                        <MenuItem value="GF">French Guiana</MenuItem>
                        <MenuItem value="PF">French Polynesia</MenuItem>
                        <MenuItem value="TF">French Southern Terr.</MenuItem>
                        <MenuItem value="GA">Gabon</MenuItem>
                        <MenuItem value="GM">Gambia</MenuItem>
                        <MenuItem value="GE">Georgia</MenuItem>
                        <MenuItem value="DE">Germany</MenuItem>
                        <MenuItem value="GH">Ghana</MenuItem>
                        <MenuItem value="GI">Gibraltar</MenuItem>
                        <MenuItem value="GR">Greece</MenuItem>
                        <MenuItem value="GL">Greenland</MenuItem>
                        <MenuItem value="GD">Grenada</MenuItem>
                        <MenuItem value="GP">Guadeloupe</MenuItem>
                        <MenuItem value="GU">Guam</MenuItem>
                        <MenuItem value="GT">Guatemala</MenuItem>
                        <MenuItem value="GN">Guinea</MenuItem>
                        <MenuItem value="GW">Guinea-Bissau</MenuItem>
                        <MenuItem value="GY">Guyana</MenuItem>
                        <MenuItem value="HT">Haiti</MenuItem>
                        <MenuItem value="HM">Heard/McDonald Isls.</MenuItem>
                        <MenuItem value="HN">Honduras</MenuItem>
                        <MenuItem value="HK">Hong Kong</MenuItem>
                        <MenuItem value="HU">Hungary</MenuItem>
                        <MenuItem value="IS">Iceland</MenuItem>
                        <MenuItem value="IN">India</MenuItem>
                        <MenuItem value="ID">Indonesia</MenuItem>
                        <MenuItem value="IR">Iran</MenuItem>
                        <MenuItem value="IQ">Iraq</MenuItem>
                        <MenuItem value="IE">Ireland</MenuItem>
                        <MenuItem value="IL">Israel</MenuItem>
                        <MenuItem value="IT">Italy</MenuItem>
                        <MenuItem value="JM">Jamaica</MenuItem>
                        <MenuItem value="JP">Japan</MenuItem>
                        <MenuItem value="JO">Jordan</MenuItem>
                        <MenuItem value="KZ">Kazakhstan</MenuItem>
                        <MenuItem value="KE">Kenya</MenuItem>
                        <MenuItem value="KI">Kiribati</MenuItem>
                        <MenuItem value="KP">Korea (North)</MenuItem>
                        <MenuItem value="KR">Korea (South)</MenuItem>
                        <MenuItem value="KW">Kuwait</MenuItem>
                        <MenuItem value="KG">Kyrgyzstan</MenuItem>
                        <MenuItem value="LA">Laos</MenuItem>
                        <MenuItem value="LV">Latvia</MenuItem>
                        <MenuItem value="LB">Lebanon</MenuItem>
                        <MenuItem value="LS">Lesotho</MenuItem>
                        <MenuItem value="LR">Liberia</MenuItem>
                        <MenuItem value="LY">Libya</MenuItem>
                        <MenuItem value="LI">Liechtenstein</MenuItem>
                        <MenuItem value="LT">Lithuania</MenuItem>
                        <MenuItem value="LU">Luxembourg</MenuItem>
                        <MenuItem value="MO">Macau</MenuItem>
                        <MenuItem value="MK">Macedonia</MenuItem>
                        <MenuItem value="MG">Madagascar</MenuItem>
                        <MenuItem value="MW">Malawi</MenuItem>
                        <MenuItem value="MY">Malaysia</MenuItem>
                        <MenuItem value="MV">Maldives</MenuItem>
                        <MenuItem value="ML">Mali</MenuItem>
                        <MenuItem value="MT">Malta</MenuItem>
                        <MenuItem value="MH">Marshall Islands</MenuItem>
                        <MenuItem value="MQ">Martinique</MenuItem>
                        <MenuItem value="MR">Mauritania</MenuItem>
                        <MenuItem value="MU">Mauritius</MenuItem>
                        <MenuItem value="YT">Mayotte</MenuItem>
                        <MenuItem value="MX">Mexico</MenuItem>
                        <MenuItem value="FM">Micronesia</MenuItem>
                        <MenuItem value="MD">Moldova</MenuItem>
                        <MenuItem value="MC">Monaco</MenuItem>
                        <MenuItem value="MN">Mongolia</MenuItem>
                        <MenuItem value="MS">Montserrat</MenuItem>
                        <MenuItem value="MA">Morocco</MenuItem>
                        <MenuItem value="MZ">Mozambique</MenuItem>
                        <MenuItem value="MM">Myanmar</MenuItem>
                        <MenuItem value="MP">N. Mariana Isls.</MenuItem>
                        <MenuItem value="NA">Namibia</MenuItem>
                        <MenuItem value="NR">Nauru</MenuItem>
                        <MenuItem value="NP">Nepal</MenuItem>
                        <MenuItem value="NL">Netherlands</MenuItem>
                        <MenuItem value="AN">Netherlands Antilles</MenuItem>
                        <MenuItem value="NC">New Caledonia</MenuItem>
                        <MenuItem value="NZ">New Zealand</MenuItem>
                        <MenuItem value="NI">Nicaragua</MenuItem>
                        <MenuItem value="NE">Niger</MenuItem>
                        <MenuItem value="NG">Nigeria</MenuItem>
                        <MenuItem value="NU">Niue</MenuItem>
                        <MenuItem value="NF">Norfolk Island</MenuItem>
                        <MenuItem value="NO">Norway</MenuItem>
                        <MenuItem value="OM">Oman</MenuItem>
                        <MenuItem value="PK">Pakistan</MenuItem>
                        <MenuItem value="PW">Palau</MenuItem>
                        <MenuItem value="PS">
                          Palestinian Territory, Occupied
                        </MenuItem>
                        <MenuItem value="PA">Panama</MenuItem>
                        <MenuItem value="PG">Papua New Guinea</MenuItem>
                        <MenuItem value="PY">Paraguay</MenuItem>
                        <MenuItem value="PE">Peru</MenuItem>
                        <MenuItem value="PH">Philippines</MenuItem>
                        <MenuItem value="PN">Pitcairn</MenuItem>
                        <MenuItem value="PL">Poland</MenuItem>
                        <MenuItem value="PT">Portugal</MenuItem>
                        <MenuItem value="PR">Puerto Rico</MenuItem>
                        <MenuItem value="QA">Qatar</MenuItem>
                        <MenuItem value="RE">Reunion</MenuItem>
                        <MenuItem value="RO">Romania</MenuItem>
                        <MenuItem value="RU">Russian Federation</MenuItem>
                        <MenuItem value="RW">Rwanda</MenuItem>
                        <MenuItem value="KN">Saint Kitts and Nevis</MenuItem>
                        <MenuItem value="LC">Saint Lucia</MenuItem>
                        <MenuItem value="WS">Samoa</MenuItem>
                        <MenuItem value="SM">San Marino</MenuItem>
                        <MenuItem value="ST">Sao Tome/Principe</MenuItem>
                        <MenuItem value="SA">Saudi Arabia</MenuItem>
                        <MenuItem value="SN">Senegal</MenuItem>
                        <MenuItem value="CS">Serbia and Montenegro</MenuItem>
                        <MenuItem value="SC">Seychelles</MenuItem>
                        <MenuItem value="SL">Sierra Leone</MenuItem>
                        <MenuItem value="SG">Singapore</MenuItem>
                        <MenuItem value="SK">Slovak Republic</MenuItem>
                        <MenuItem value="SI">Slovenia</MenuItem>
                        <MenuItem value="SB">Solomon Islands</MenuItem>
                        <MenuItem value="SO">Somalia</MenuItem>
                        <MenuItem value="ZA">South Africa</MenuItem>
                        <MenuItem value="ES">Spain</MenuItem>
                        <MenuItem value="LK">Sri Lanka</MenuItem>
                        <MenuItem value="SH">St. Helena</MenuItem>
                        <MenuItem value="PM">St. Pierre and Miquelon</MenuItem>
                        <MenuItem value="VC">
                          St. Vincent and Grenadines
                        </MenuItem>
                        <MenuItem value="SD">Sudan</MenuItem>
                        <MenuItem value="SR">Suriname</MenuItem>
                        <MenuItem value="SJ">Svalbard/Jan Mayen Isls.</MenuItem>
                        <MenuItem value="SZ">Swaziland</MenuItem>
                        <MenuItem value="SE">Sweden</MenuItem>
                        <MenuItem value="CH">Switzerland</MenuItem>
                        <MenuItem value="SY">Syria</MenuItem>
                        <MenuItem value="TW">Taiwan</MenuItem>
                        <MenuItem value="TJ">Tajikistan</MenuItem>
                        <MenuItem value="TZ">Tanzania</MenuItem>
                        <MenuItem value="TH">Thailand</MenuItem>
                        <MenuItem value="TL">Timor-Leste</MenuItem>
                        <MenuItem value="TG">Togo</MenuItem>
                        <MenuItem value="TK">Tokelau</MenuItem>
                        <MenuItem value="TO">Tonga</MenuItem>
                        <MenuItem value="TT">Trinidad and Tobago</MenuItem>
                        <MenuItem value="TN">Tunisia</MenuItem>
                        <MenuItem value="TR">Turkey</MenuItem>
                        <MenuItem value="TM">Turkmenistan</MenuItem>
                        <MenuItem value="TC">Turks/Caicos Isls.</MenuItem>
                        <MenuItem value="TV">Tuvalu</MenuItem>
                        <MenuItem value="UG">Uganda</MenuItem>
                        <MenuItem value="UA">Ukraine</MenuItem>
                        <MenuItem value="AE">United Arab Emirates</MenuItem>
                        <MenuItem value="UY">Uruguay</MenuItem>
                        <MenuItem value="UM">US Minor Outlying Is.</MenuItem>
                        <MenuItem value="UZ">Uzbekistan</MenuItem>
                        <MenuItem value="VU">Vanuatu</MenuItem>
                        <MenuItem value="VA">Vatican City</MenuItem>
                        <MenuItem value="VE">Venezuela</MenuItem>
                        <MenuItem value="VN">Viet Nam</MenuItem>
                        <MenuItem value="VG">Virgin Islands (British)</MenuItem>
                        <MenuItem value="VI">Virgin Islands (U.S.)</MenuItem>
                        <MenuItem value="WF">Wallis/Futuna Isls.</MenuItem>
                        <MenuItem value="EH">Western Sahara</MenuItem>
                        <MenuItem value="YE">Yemen</MenuItem>
                        <MenuItem value="ZM">Zambia</MenuItem>
                        <MenuItem value="ZW">Zimbabwe</MenuItem>
                      </Select>
                      <FormHelperText>{errorMessage.country}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={classes.leftalign}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          inputRef={this.inputPrivacy}
                        />
                      }
                      label="I agree to receive communications in accordance with Arrow’s Privacy Policy."
                    />
                    <FormHelperText>{errorMessage.inputprivacy}</FormHelperText>
                  </Grid>
                  <Grid item xs={12} className={classes.leftalign}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          inputRef={this.inputSponsor}
                        />
                      }
                      label="I agree for above information to be shared with a sponsoring vendor."
                    />
                    <FormHelperText>{errorMessage.inputsponsor}</FormHelperText>
                  </Grid>
                </Grid>

                {/* <Button type="Submit" value="Submit" className="registerButton toupper" /> */}

                <Button
                  type="Submit"
                  value="Submit"
                  className="registerButton toupper"
                >
                  Submit
                </Button>

                {/* <input type="hidden" name="hiddenField" id="fe28271" value="~~eloqua..type--emailfield..syntax--utm_source1~~" />
                    <input type="hidden" name="hiddenField2" id="fe28272" value="~~eloqua..type--emailfield..syntax--utm_medium1~~" />
                    <input type="hidden" name="hiddenField3" id="fe28273" value="~~eloqua..type--emailfield..syntax--utm_campaign1~~" />
                    <input type="hidden" name="hiddenField4" id="fe28274" value="~~eloqua..type--emailfield..syntax--utm_term1~~" />
                    <input type="hidden" name="hiddenField5" id="fe28275" value="~~eloqua..type--emailfield..syntax--utm_content1~~" />
                 */}
                {/* </form> */}
              </form>
            </>
          ) : (
            <div className="container logForm">
              <h2>My Settings</h2>
              &nbsp;&nbsp;&nbsp;Not logged on
            </div>
          )}
        </Paper>
      </Container>
    );
  }
}
ProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilePage)),
);
