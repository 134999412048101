/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { checkLogin } from '../../actions/checkLogin';
import { flushStore } from '../../store';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ checkLogin }, dispatch);
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  gui: state.gui,
});

const styles = () => ({
  root: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    padding: 15,
    textAlign: 'center',
    background: '#22252a',
    opacity: '0.90',
    borderRadius: 4,
    margin: '5px auto',
    marginTop: '20px',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  forminput: {
    // color: 'black',
  },
  arrowfont: {
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  leftalign: {
    textAlign: 'left',
  },
});

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: '',
      pass: '',
      alert: {
        msg: 'Login as an existing user or register',
        status: '',
      },
    };
  }

  loginChange = (e) => {
    this.setState({ login: e.target.value });
  };

  passChange = (e) => {
    this.setState({ pass: e.target.value });
  };

  googleSignIn = () => {
    const { location } = this.props;

    flushStore().then(() => {
      if (location.state && location.state.from) {
        Auth.federatedSignIn({
          provider: 'Google',
          customState: location.state.from,
        });
      } else {
        Auth.federatedSignIn({
          provider: 'Google',
          customState: '/',
        });
      }
    });
  };

  Cancel = () => {
    window.location.href = process.env.REACT_APP_COMMON_URL;
  };

  signIn = (event) => {
    const { login, pass } = this.state;
    const { checkLogin: checkLoginAlt, history, gui } = this.props;

    event.preventDefault();
    let username = login.trim();

    if (!username) {
      username = gui.registerUsername;
    }
    if (!username) {
      this.setState({
        alert: { msg: 'Login cannot be empty.', status: 'alert' },
      });
    } else if (!pass) {
      this.setState({
        alert: { msg: 'Password cannot be empty.', status: 'alert' },
      });
    } else {
      Auth.signIn(username, pass)
        .then((user) => {
          document.getElementById('form-inputs').innerHTML = '';
          checkLoginAlt(user.signInUserSession, user.attributes);
          const { location } = this.props;

          if (location.state && location.state.from) {
            history.push(location.state.from);
          } else {
            history.push('/configs');
          }
        })
        .catch((err) =>
          this.setState({ alert: { msg: err.message, status: 'alert' } }),
        );
    }
  };

  render() {
    const { classes, gui } = this.props;
    const { alert } = this.state;
    const prefill = gui.registerUsername ? gui.registerUsername : '';

    return (
      <Container className={classes.root}>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.arrowfont} gutterBottom>
            Login
          </Typography>

          <p className={alert.status}>{alert.msg}</p>

          <Button
            id="google-login"
            waves="light"
            className="googleButton"
            onClick={this.googleSignIn}
          >
            <img
              className="fleft"
              src="/img/google-logo.png"
              height="34"
              alt="Google's logo"
            />
            <span className="fleft" style={{ paddingLeft: '10px' }}>
              Sign in with Google account
            </span>
          </Button>

          <Grid
            container
            spacing={5}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            style={{ marginTop: '30px' }}
          >
            <Grid item xs={4}>
              <Typography
                variant="h5"
                className={classes.arrowfont}
                gutterBottom
              >
                Existing Users
              </Typography>

              <form id="form-inputs" onSubmit={this.signIn}>
                <Grid
                  container
                  spacing={3}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="top"
                  style={{ marginTop: '10px', marginBottom: '20px' }}
                >
                  <Grid item xs={12}>
                    <TextField
                      required
                      className={classes.forminput}
                      label="E-mail"
                      fullWidth
                      onChange={this.loginChange}
                      defaultValue={prefill}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="password"
                      required
                      className={classes.forminput}
                      label="Password"
                      fullWidth
                      onChange={this.passChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" onClick={this.Cancel}>
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      style={{ backgroundColor: '#91CDD4', color: '#000' }}
                    >
                      Sign in
                    </Button>
                  </Grid>
                </Grid>
                <a
                  href="/#passwordreset"
                  style={{
                    marginTop: '40px',
                    textDecoration: 'none',
                    color: '#fff',
                  }}
                >
                  Reset Password
                </a>
              </form>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={4}>
              <Typography
                variant="h5"
                className={classes.arrowfont}
                gutterBottom
              >
                New Users
              </Typography>
              <a
                href="/#register"
                style={{ textDecoration: 'none', color: '#fff' }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ backgroundColor: '#91CDD4', color: '#000' }}
                >
                  Register Here
                </Button>
              </a>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    ); // return end
  } // render end
} // class LoginPage end
LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage)),
);
