/* eslint-disable react/require-default-props */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    flexGrow: 1,
  },
};

class ReportDialog extends React.Component {
  handleClose = (value) => {
    const { onClose: onCloseAlt } = this.props;

    onCloseAlt(value);
  };

  render() {
    const { ...other } = this.props;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="Create Report"
        {...other}
      >
        <DialogTitle id="form-dialog-title">Create Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Submit additional information for report generation
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="report-customername"
            label="Customer Name"
            InputProps={{ disableUnderline: true }}
            fullWidth
            variant="filled"
          />
          <TextField
            margin="dense"
            id="report-customercontact"
            label="Customer Contact"
            InputProps={{ disableUnderline: true }}
            fullWidth
            variant="filled"
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => this.handleClose(true)}>
            Download
          </Button>
          <Button onClick={() => this.handleClose('cancel')} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
ReportDialog.propTypes = {
  onClose: PropTypes.func,
};

export default withStyles(styles)(ReportDialog);
