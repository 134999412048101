import { API } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { Buffer } from 'buffer';

import { ApiTemplatesFetch } from '../ApiTemplates';

export const TEMPLATE_SAVEPREPARE = 'TEMPLATE_SAVEPREPARE';
export const TEMPLATE_SAVESTART = 'TEMPLATE_SAVESTART';
export const TEMPLATE_SAVEERROR = 'TEMPLATE_SAVEERROR';
export const TEMPLATE_SAVED = 'TEMPLATE_SAVED';

export const TEMPLATE_LOADSTART = 'TEMPLATE_LOADSTART';
export const TEMPLATE_LOADERROR = 'TEMPLATE_LOADERROR';
export const TEMPLATE_LOADED = 'TEMPLATE_LOADED';

export const TEMPLATE_REMOVESTART = 'TEMPLATE_REMOVESTART';
export const TEMPLATE_REMOVEERROR = 'TEMPLATE_REMOVEERROR';
export const TEMPLATE_REMOVED = 'TEMPLATE_REMOVED';

export function ApiTemplateSavePrepare(payload) {
  return {
    type: TEMPLATE_SAVEPREPARE,
    payload,
  };
}
export function ApiTemplateSaveStart(payload) {
  return {
    type: TEMPLATE_SAVESTART,
    payload,
  };
}
export function ApiTemplateLoadStart(payload) {
  return {
    type: TEMPLATE_LOADSTART,
    payload,
  };
}
export function ApiTemplateRemoveStart(payload) {
  return {
    type: TEMPLATE_REMOVESTART,
    payload,
  };
}
export function ApiTemplateSaveError(payload) {
  return {
    type: TEMPLATE_SAVEERROR,
    payload,
  };
}
export function ApiTemplateLoadError(payload) {
  return {
    type: TEMPLATE_LOADERROR,
    payload,
  };
}
export function ApiTemplateRemoveError(payload) {
  return {
    type: TEMPLATE_REMOVEERROR,
    payload,
  };
}
export function ApiTemplateSaved(payload) {
  return {
    // payload should contain configId
    type: TEMPLATE_SAVED,
    payload,
  };
}
export function ApiTemplateLoaded(payload) {
  return {
    type: TEMPLATE_LOADED,
    payload,
  };
}
export function ApiTemplateRemoved(payload) {
  return {
    type: TEMPLATE_REMOVED,
    payload,
  };
}

export function ApiTemplateSave(payload) {
  return function disp(dispatch) {
    // fetch starting
    dispatch(ApiTemplateSaveStart(payload));

    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/templates';

    // existing loaded config ?

    // new config

    const templateId = uuid();

    const buff = Buffer.from(payload.template);
    const encoded = buff.toString('base64');

    const myInit = {
      body: {
        templateId,
        content: encoded,
        dateCreated: Date.now(),
        description: payload.description,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    };

    API.post(apiName, path, myInit)
      .then((response) => {
        // response should contain configId
        dispatch(ApiTemplateSaved({ templateId, response }));
      })
      .catch((error) => {
        console.error(error.response);
        dispatch(ApiTemplateSaveError(error.response));
      });
  };
}
export function ApiTemplateRemove(payload) {
  return function disp(dispatch) {
    dispatch(ApiTemplateRemoveStart(payload));

    const { configId } = payload;

    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/configs/${configId}`;
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    API.del(apiName, path, myInit)
      .then((response) => {
        response.data.configId = configId;

        dispatch(ApiTemplateRemoved(response.data));
        dispatch(ApiTemplatesFetch(true));
      })
      .catch((error) => {
        console.error(error.response);
        dispatch(ApiTemplateRemoveError(error.response));
      });
  };
}
export function ApiTemplateLoad(payload) {
  return function disp(dispatch) {
    // fetch starting
    dispatch(ApiTemplateLoadStart(payload));

    const { configId } = payload;

    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/configs/${configId}`;
    const myInit = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    API.get(apiName, path, myInit)
      .then((response) => {
        response.data.configId = configId;
        const buff = Buffer.from(response.data.content, 'base64');
        const decoded = buff.toString('ascii');

        dispatch(ApiTemplateLoaded(decoded));
      })
      .catch((error) => {
        console.error(error.response);
      });
  };
}
