/* eslint-disable no-restricted-syntax */
class ConfigExport {
  static exportJson(model, analysisItems) {
    // generate JSON used for audit reports
    if (model && model.objects) {
      const exportobject = {};
      const analysisoutput = [];

      // Based on findings
      exportobject.ssl_default_profile_enabled = false;
      exportobject.enhancedauthfeedbackenabled = false;
      exportobject.softlockoutenabled = true;
      exportobject.strongpasswordsenabled = true;

      for (const analysisItem of analysisItems) {
        const item = {};

        item.matched = analysisItem.matched;
        item.identifier = analysisItem.rule.identifier;
        item.name = analysisItem.rule.name;
        item.severity = analysisItem.rule.severity;
        item.description = analysisItem.rule.description;
        item.objects = analysisItem.objects;

        analysisoutput.push(item);

        if (item.identifier === 'SEC-015' && item.matched) {
          exportobject.ssl_default_profile_enabled = true;
        }
        if (item.identifier === 'SEC-021' && item.matched) {
          exportobject.enhancedauthfeedbackenabled = true;
        }
        if (item.identifier === 'SEC-009' && item.matched) {
          exportobject.softlockoutenabled = false;
        }
        if (item.identifier === 'SEC-005' && item.matched) {
          exportobject.strongpasswordsenabled = false;
        }
      }

      exportobject.findings = analysisoutput;

      let hostname = 'export';

      if (model.objects.nshostname) {
        const hostnameobj = model.objects.nshostname.items[0];

        hostname = hostnameobj.hostname;
      }
      exportobject.instancename = hostname;

      let nsip = 'unknown';

      if (model.objects['nsconfig-ip']) {
        const nsipobj = model.objects['nsconfig-ip'].items[0];

        nsip = nsipobj.ipaddress;
      }
      exportobject.nsip = nsip;

      let firmware = 'unknown';

      if (model.objects.firmware) {
        const firmwareobj = model.objects.firmware.items[0];

        firmware = `${firmwareobj.major}.${firmwareobj.minor}.${firmwareobj.build}`;
      }
      exportobject.firmware = firmware;

      let modes = 'unknwon';

      if (model.objects.nsmode) {
        const modesobj = model.objects.nsmode.items[0];

        modes = modesobj.mode;
      }
      exportobject.modes = modes;

      let ha = false;

      if (model.objects.hanode) {
        ha = true;
      }
      exportobject.HA = ha;

      const blob = new Blob([JSON.stringify(exportobject, null, 2)]);

      if (window.navigator.msSaveOrOpenBlob)
        // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveBlob(blob, `${hostname}.json`);
      else {
        const a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(blob, { type: 'application/json' });
        a.download = `${hostname}.json`;
        document.body.appendChild(a);
        a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);
      }
    }
  }

  static export(model) {
    // create array with all line numbers and original lines
    if (model && model.objects) {
      const exportlines = [];

      for (let i = 0; i < Object.keys(model.objects).length; i += 1) {
        const objecttype = Object.keys(model.objects)[i];

        const objectitems = model.objects[objecttype].items;

        for (let j = 0; j < objectitems.length; j += 1) {
          const item = objectitems[j];

          exportlines.push({
            line: item.line,
            baseline: item.baseline,
          });
        }
      }

      // sort on line number
      const sorted = exportlines.sort((a, b) => (a.line > b.line ? 1 : -1));
      const result = sorted.map((a) => a.baseline);

      const blob = new Blob([result.join('\r\n')]);

      if (window.navigator.msSaveOrOpenBlob)
        // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveBlob(blob, 'export.txt');
      else {
        const a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
        a.download = 'export.txt';
        document.body.appendChild(a);
        a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);
      }
    }
  }

  static exportPdf2(blob) {
    // console.log('reducer exporting');
    if (window.navigator.msSaveOrOpenBlob)
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, `report.pdf`);
    else {
      const a = window.document.createElement('a');

      a.href = window.URL.createObjectURL(blob, { type: 'application/pdf' });
      a.download = `xpand-healthcheck-report.pdf`;
      document.body.appendChild(a);
      a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      document.body.removeChild(a);
    }
  }

  static exportPdf({ url, name }) {
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  }
}
export default ConfigExport;
