/* eslint-disable no-console */
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PublishIcon from '@material-ui/icons/Publish';

import { addInput } from '../../../actions/healthcheck/addInput';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addInput }, dispatch);
}

const mapStateToProps = (state) => ({
  inputs: state.healthcheck.inputs,
});

// const baseStyle = {
//   flex: 1,
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   padding: '20px',
//   borderWidth: 2,
//   borderRadius: 2,
//   borderColor: '#eeeeee',
//   borderStyle: 'dashed',
//   backgroundColor: '#fafafa',
//   color: '#bdbdbd',
//   outline: 'none',
//   transition: 'border .24s ease-in-out',
// };

// const activeStyle = {
//   borderColor: '#2196f3',
// };

// const acceptStyle = {
//   borderColor: '#00e676',
// };

// const rejectStyle = {
//   borderColor: '#ff1744',
// };

class DropFile extends Component {
  onDrop = (acceptedFiles) => {
    const { addInput: addInputFn } = this.props;

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('File reading was aborted');
      reader.onerror = () => console.log('File reading error');
      reader.onload = () => {
        const text = reader.result;

        addInputFn({ text, file: file.name });
        console.log(file.name);
      };
      reader.readAsText(file);
    });
  };

  render() {
    return (
      <div className="container">
        <Dropzone className="healthcheckdropzone" onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <section style={{ textAlign: 'center' }}>
              <div
                className="healthcheckdropzone"
                style={{ textAlign: 'center' }}
                {...getRootProps({ className: 'healthcheckdropzone' })}
              >
                <input {...getInputProps()} />
                <p>
                  Drag &apos;n drop JSON input files here for each ADC instance
                </p>
                <div className="dropzone-inner">
                  {/* <Icon large>file_upload</Icon> */}
                  <PublishIcon style={{ fontSize: '4rem' }} />
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DropFile);
