import { event } from '../../utils/ga-utils';

export const LOAD_CONF = 'LOAD_CONF';
export const FETCH_CONF_START = 'FETCH_CONF_START';
export const FETCH_CONF_END = 'FETCH_CONF_END';
export const EXPORT_CONF = 'EXPORT_CONF';
export const EXPORT_JSON = 'EXPORT_JSON';
export const EXPORT_PDF = 'EXPORT_PDF';

export function fetchConf(file) {
  return {
    type: LOAD_CONF,
    payload: file,
  };
}
export function exportConf(payload) {
  event('Config', 'Export');

  return {
    type: EXPORT_CONF,
    payload,
  };
}

export function exportJson(payload) {
  return {
    type: EXPORT_JSON,
    payload,
  };
}

export function exportPdfReport(payload) {
  return {
    type: EXPORT_PDF,
    payload,
  };
}

export function FetchConfEnd(payload) {
  return {
    type: FETCH_CONF_END,
    payload,
  };
}
export function FetchConfStart(payload) {
  return {
    type: FETCH_CONF_START,
    payload,
  };
}

export function FetchConfig(payload) {
  return function disp(dispatch) {
    // fetch starting
    event('Config', 'Loaded');
    dispatch(FetchConfStart(payload));
    dispatch(fetchConf(payload));
  };
}
