/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Auth } from 'aws-amplify';

import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FolderSpecial from '@material-ui/icons/FolderSpecial';
import Heart from '@material-ui/icons/LocalHospital';
import FiberNew from '@material-ui/icons/FiberNew';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { authLogout } from '../../actions/authLogout';
import { checkLogin } from '../../actions/checkLogin';
import { UpdatePath } from '../../actions/gui';

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  subHeading: {
    fontSize: 18,
    color: '#ddd',
    fontFamily: '"Arrow Display","Helvetica Neue"',
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  configsButton: {
    marginLeft: 20,
  },
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ checkLogin, authLogout, UpdatePath }, dispatch);
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});

class TopBar extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    anchorEl: null,
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  openSettings = (e) => {
    const { history } = this.props;

    this.handleClose();
    e.preventDefault();
    history.push('/profile');
  };

  openFeedback = (e) => {
    // gets the iframe on which the widget is embedded
    this.handleClose();
    e.preventDefault();
    const iframe = document.querySelector('#jsd-widget');

    // gets the content of the iframe

    const iframeContent = iframe.contentDocument;

    // gets button which opens the widget
    const button = iframeContent.querySelector('#help-button');

    button.click();
  };

  openAbout = (e) => {
    this.handleClose();
    e.preventDefault();
    // this.props.history.push('/about');
    window.open('https://arrow.com/ecs/xpand', '_blank');
  };

  loginClick = (e) => {
    const { history, location } = this.props;

    this.handleClose();
    e.preventDefault();
    history.push({
      pathname: '/login',
      state: { from: location.pathname },
    });
  };

  signOut = (e) => {
    const { authLogout: authLogoutAlt } = this.props;

    this.handleClose();
    e.preventDefault();
    Auth.signOut()
      .then(() => {
        authLogoutAlt(true);
      })
      .catch((err) => console.error('err', err));
  };

  render() {
    const { classes, location, auth } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    let topbarclass = 'topbar-outer';

    if (location.pathname === '/' || location.pathname === '/about') {
      topbarclass = 'topbar-home';
    }
    const { isAuthenticated, username, firstname, td } = auth;

    const loginText = isAuthenticated ? firstname : 'Log In';

    return (
      <div className={topbarclass}>
        <div className="topbar-arrow">
          <a href={process.env.REACT_APP_COMMON_URL}>
            <img
              className="arrow-img"
              height="20"
              src="img/arrow-logo.svg"
              alt="Arrow"
            />
          </a>
        </div>
        <div className="topbar-inner">
          <AppBar position="static">
            <Toolbar>
              {/*      <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                      <MenuIcon />
                    </IconButton> */}
              <Typography
                variant="h3"
                color="inherit"
                className={`subHeading ${classes.subHeading} ${classes.flex}`}
              >
                Enterprise Computing Solutions&nbsp;&nbsp;&nbsp;
              </Typography>
              {isAuthenticated && (
                <Button
                  className="configsButton"
                  component={Link}
                  to="/configs"
                  startIcon={<FolderSpecial />}
                >
                  My Configs
                </Button>
              )}
              {isAuthenticated && td && (
                <Button
                  className="configsButton"
                  component={Link}
                  to="/healthcheck"
                  startIcon={<Heart />}
                >
                  HealthCheck
                </Button>
              )}
              <Button
                className="configsButton"
                component={Link}
                to="/whatsnew"
                startIcon={<FiberNew color="secondary" />}
              >
                What&apos;s New
              </Button>
              <Typography className="subheading" color="inherit" />
              <div>
                <Button
                  aria-owns={open ? 'menu-appbar' : null}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                  startIcon={<AccountCircle />}
                >
                  {loginText}
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  {isAuthenticated && (
                    <MenuItem divider disabled>
                      {username}
                    </MenuItem>
                  )}
                  {isAuthenticated && (
                    <MenuItem onClick={this.openSettings}>My Profile</MenuItem>
                  )}

                  {isAuthenticated && (
                    <MenuItem onClick={this.signOut}>Log Out</MenuItem>
                  )}

                  {!isAuthenticated && (
                    <MenuItem divider onClick={this.loginClick}>
                      Log In
                    </MenuItem>
                  )}
                  <MenuItem divider={false} onClick={this.openFeedback}>
                    Provide Feedback
                  </MenuItem>
                  <MenuItem divider={false} onClick={this.openAbout}>
                    About
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    );
  }
}
TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBar)),
);
