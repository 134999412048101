/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getConfig } from 'xpand-parser-netscaler';
import { updateFilter } from '../../actions/updateFilter';
// import CodeEditor from '../codeEditor';
import AnalysisSummary from './AnalysisSummary';
import AnalysisItem from './AnalysisItem';
import RegisterBanner from '../RegisterBanner';

const styles = (theme) => ({
  root: {
    padding: '20px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  statsTable: {
    maxWidth: 750,
  },
  categorycountersTable: {
    padding: '0px',
  },
  topAlign: {
    verticalAlign: 'top',
    paddingTop: '24px',
  },
  categoryCellHeader: {
    color: '#222',
    backgroundColor: '#888',
    padding: '6px',
  },
  categoryCell: {
    color: '#222',
    backgroundColor: '#ccc',
    padding: '6px',
  },
  buttonCritical: {
    padding: '3px',
    margin: '0px',
    height: '25px',
    color: '#E03825',
    borderColor: '#E03825',
    marginRight: '20px',
    '&:hover': {
      borderColor: '#E03825',
    },
  },
  buttonWarning: {
    padding: '3px',
    margin: '0px',
    height: '25px',
    color: '#E57630',
    borderColor: '#E57630',
    marginRight: '20px',
    '&:hover': {
      borderColor: '#E57630',
    },
  },
  buttonMedium: {
    padding: '3px',
    margin: '0px',
    height: '25px',
    color: '#F1BE42',
    borderColor: '#F1BE42',
    marginRight: '20px',
    '&:hover': {
      borderColor: '#F1BE42',
    },
  },
  buttonLow: {
    padding: '3px',
    margin: '0px',
    height: '25px',
    color: '#89C0C8',
    borderColor: '#89C0C8',
    marginRight: '20px',
    '&:hover': {
      borderColor: '#89C0C8',
    },
  },
  itemRoot: {
    backgroundColor: '#31353E',
    borderBottom: '1px solid #727272',
  },
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateFilter }, dispatch);
}
const mapStateToProps = (state) => ({
  analysisItems: state.config.analysisItems,
  configFiltered: state.config.configFiltered,
  activeLine: state.config.activeLine,
  stats: state.config.stats,
  auth: state.auth,
  status: state.status,
});

class analysisViewer extends Component {
  constructor(props) {
    super(props);

    this.addDependencyFilter = this.addDependencyFilter.bind(this);
    this.addObjectTypeFilter = this.addObjectTypeFilter.bind(this);
  }

  state = {
    expanded: null,
  };

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  // onDependencyClick(objecttype, name) {
  //   // filter dependencies
  //   const { updateFilter: updateFilterAlt } = this.props;
  //   updateFilterAlt(`${objecttype}:${name}`);
  // }

  addDependencyFilter() {
    const {
      configFiltered,
      activeLine,
      updateFilter: updateFilterAlt,
    } = this.props;

    if (configFiltered) {
      if (configFiltered.objects) {
        for (
          let i = 0;
          i < Object.keys(configFiltered.objects).length;
          i += 1
        ) {
          const objecttype = Object.keys(configFiltered.objects)[i];
          const objectitems = configFiltered.objects[objecttype].items;

          for (let j = 0; j < objectitems.length; j += 1) {
            const curline = objectitems[j].baseline;

            if (curline === activeLine) {
              const conffile = getConfig();
              const configitem = conffile.parseconfig.find(
                (x) => x.objecttype === objecttype,
              );
              const newfilter = {};

              newfilter.filtertype = objecttype;
              newfilter.filtervalue = objectitems[j][configitem.key];

              updateFilterAlt({ replace: true, newfilter });

              return;
            }
          }
        }
      }
    }
  }

  addObjectTypeFilter() {
    const {
      configFiltered,
      activeLine,
      updateFilter: updateFilterAlt,
    } = this.props;

    //             // find original object for this line
    if (configFiltered) {
      if (configFiltered.objects) {
        for (
          let i = 0;
          i < Object.keys(configFiltered.objects).length;
          i += 1
        ) {
          const objecttype = Object.keys(configFiltered.objects)[i];
          const objectitems = configFiltered.objects[objecttype].items;

          for (let j = 0; j < objectitems.length; j += 1) {
            const curline = objectitems[j].baseline;

            if (curline === activeLine) {
              const newfilter = {};

              newfilter.filtertype = 'type';
              newfilter.filtervalue = objecttype;

              updateFilterAlt({ replace: true, newfilter });

              return;
            }
          }
        }
      }
    }
  }

  render() {
    const { classes, analysisItems, stats, auth } = this.props;
    const { expanded } = this.state;
    let firstCritical = true;
    let firstMajor = true;

    const { isAuthenticated, username } = auth;
    const corpuser = username
      ? username.toLowerCase().endsWith('blubird.eu') ||
        username.toLowerCase().endsWith('positivethinking.tech')
      : undefined;

    return (
      <div className={classes.root}>
        <AnalysisSummary analysisItems={analysisItems} />
        {auth && auth.isAuthenticated ? null : <RegisterBanner />}
        {analysisItems
          .filter((a) => a.matched)
          .sort((a, b) => a.rule.severity - b.rule.severity)
          .map((item) => {
            const newItem = { ...item, disabled: false };

            if (!auth.isAuthenticated) {
              if (newItem.rule.severity === 1) {
                if (firstCritical) {
                  newItem.disabled = false;
                  firstCritical = false;

                  return newItem;
                }
                newItem.disabled = true;

                return newItem;
              }
              if (newItem.rule.severity === 2) {
                if (firstMajor) {
                  newItem.disabled = false;
                  firstMajor = false;

                  return newItem;
                }
                newItem.disabled = true;

                return newItem;
              }
              newItem.disabled = true;

              return newItem;
            }

            return newItem;
          })
          .map((item) => (
            <AnalysisItem key={item.rule.name} item={item} />
          ))}
        <Accordion
          key="stats"
          className={classes.itemRoot}
          expanded={expanded === 'stats'}
          onChange={this.handleChange('stats')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.buttonLow}
            >
              Info
            </Button>
            <Typography className={classes.heading}>
              Config Statistics
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  component="span"
                  className={classes.secondaryHeading}
                >
                  <p className="analysisText">
                    Statistics for this configuration:
                  </p>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <div className="fullwidth">
                  <Table size="small" className={classes.statsTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Statistic</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Analysis Findings</TableCell>
                        <TableCell>
                          Critical=
                          {
                            analysisItems
                              .filter((x) => x.matched)
                              .filter((x) => x.rule.severity === 1).length
                          }
                          &nbsp; Major=
                          {
                            analysisItems
                              .filter((x) => x.matched)
                              .filter((x) => x.rule.severity === 2).length
                          }{' '}
                          &nbsp; Medium=
                          {
                            analysisItems
                              .filter((x) => x.matched)
                              .filter((x) => x.rule.severity === 3).length
                          }{' '}
                          &nbsp; Info=
                          {analysisItems
                            .filter((x) => x.matched)
                            .filter((x) => x.rule.severity === 4).length +
                            analysisItems
                              .filter((x) => x.matched)
                              .filter((x) => x.rule.severity === 5).length}{' '}
                          &nbsp;
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Firmware</TableCell>
                        <TableCell>{stats.firmware} </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Enabled Features</TableCell>
                        <TableCell>{stats.features} </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Total Number of lines</TableCell>
                        <TableCell>{stats.linecount} </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>VIPs</TableCell>
                        <TableCell>{stats.vipcount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>AppFW Rules</TableCell>
                        <TableCell>{stats.appfwcount} </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Pattern Sets / String Maps</TableCell>
                        <TableCell>{stats.appexpertcount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>SSL Key-Pairs</TableCell>
                        <TableCell>{stats.certkeys}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Admin Partitions</TableCell>
                        <TableCell>{stats.partitions}</TableCell>
                      </TableRow>
                      {isAuthenticated && corpuser ? (
                        <TableRow>
                          <TableCell className={classes.topAlign}>
                            Objects per category
                          </TableCell>
                          <TableCell>
                            <Table className={classes.categorycountersTable}>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className={classes.categoryCellHeader}
                                  >
                                    Category
                                  </TableCell>
                                  <TableCell
                                    className={classes.categoryCellHeader}
                                  >
                                    Count
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {stats.categorycounters &&
                                Object.keys(stats.categorycounters).map(
                                  (key) => (
                                    <TableRow key={key}>
                                      <TableCell
                                        className={classes.categoryCell}
                                      >
                                        {key}
                                      </TableCell>
                                      <TableCell
                                        className={classes.categoryCell}
                                      >
                                        {stats.categorycounters[key]}
                                      </TableCell>
                                    </TableRow>
                                  ),
                                )}
                            </Table>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}
analysisViewer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(analysisViewer),
);
