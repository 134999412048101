/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import DialogContent from '@material-ui/core/DialogContent';
import Backdrop from '@material-ui/core/Backdrop';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIconOutlined from '@material-ui/icons/InsertDriveFileOutlined';
import { authLogout } from '../../actions/authLogout';
import { ApiConfigLoad, ApiConfigRemove } from '../../actions/ApiConfig';
import { ApiConfigsFetch } from '../../actions/ApiConfigs';
import { checkLogin } from '../../actions/checkLogin';
import ToolBar from '../../components/toolbar';
import { withParser } from '../../ParserContext';

const styles = (theme) => ({
  root: {
    flex: 1,
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  backdrop: {
    zIndex: 11,
    color: '#fff',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    // background: '#031323',
    background: 'none',
    // color: 'black',
    boxShadow: 'none',
    marginTop: '20px',
  },
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { checkLogin, authLogout, ApiConfigsFetch, ApiConfigLoad, ApiConfigRemove },
    dispatch,
  );
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  apiconfigs: state.apiconfigs,
  status: state.status,
});
// Components

class ConfirmDialog extends React.Component {
  handleClose = (value) => {
    const { onClose: onCloseAlt } = this.props;

    onCloseAlt(value);
  };

  render() {
    const { ...other } = this.props;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ root: { backgroundColor: '#fff' } }}
        {...other}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to remove this configuration?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will remove the configuration file from XConfig.io
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose('cancel')} color="default">
            Cancel
          </Button>
          <Button
            onClick={() => this.handleClose('remove')}
            color="secondary"
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
ConfirmDialog.propTypes = {
  onClose: PropTypes.func,
};

const ConfirmDialogWrapped = ConfirmDialog;

class ConfigsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDialogOpen: false,
      dialogConfig: '',
    };
  }

  componentDidMount() {
    const { ApiConfigsFetch: ApiConfigsFetchAlt } = this.props;

    // fetch configs from API
    ApiConfigsFetchAlt(true);
  }

  handleConfirmDialogOpen = (e) => {
    this.setState({ confirmDialogOpen: true, dialogConfig: e });
  };

  handleConfirmDialogClose = (value) => {
    const { dialogConfig } = this.state;

    if (value === 'remove') {
      this.RemoveConfig(dialogConfig);
    }
    this.setState({ confirmDialogOpen: false, dialogConfig: '' });
  };

  LoadConfig = (configId, fileName) => {
    const {
      ApiConfigLoad: ApiConfigLoadAlt,
      history,
      parse,
      auth,
    } = this.props;

    ApiConfigLoadAlt({ configId, auth }, parse, fileName);
    history.push('snippet');
  };

  RemoveConfig = (configId) => {
    const { ApiConfigRemove: ApiConfigRemoveAlt, auth } = this.props;

    ApiConfigRemoveAlt({ configId, auth });
  };

  ConfigRow(props) {
    return (
      <TableRow
        key={props.configId}
        style={{ height: 1, backgroundColor: '#212529', opacity: 0.9 }}
      >
        <TableCell>
          <InsertDriveFileIconOutlined style={{ fontSize: 20 }} />
          <a
            href="#snippet"
            onClick={() => this.LoadConfig(props.configId, props.filename)}
          >
            {props.filename}
          </a>
        </TableCell>
        <TableCell>{props.customer}</TableCell>
        <TableCell>{props.description}</TableCell>
        <TableCell>
          {new Date(props.datecreated).toLocaleDateString()}
        </TableCell>
        <TableCell>
          <a
            href="#snippet"
            onClick={() => this.LoadConfig(props.configId, props.filename)}
            aria-label="snippet"
          >
            <EditIcon />
          </a>
          <a
            href="#configs"
            onClick={() => this.handleConfirmDialogOpen(props.configId)}
            aria-label="configs"
          >
            <DeleteIcon />
          </a>
        </TableCell>
      </TableRow>
    );
  }

  ConfigList(rows) {
    return <TableBody>{rows.map((row) => this.ConfigRow(row))}</TableBody>;
  }

  render() {
    const { classes, apiconfigs, status, auth } = this.props;
    const { confirmDialogOpen } = this.state;

    if (apiconfigs.isError) {
      return <div>{apiconfigs.errorMessage}</div>;
    }
    let loading;

    loading = '';
    if (status.isConfigActivity) {
      loading = (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
          &nbsp;&nbsp; {status.currentActivity}
        </Backdrop>
      );
    }

    return (
      <Container maxWidth className={classes.root}>
        <ToolBar />
        <Paper className={classes.paper}>
          <Box minHeight="400px">
            {loading}
            <h2>My Configs</h2>
            {auth.isAuthenticated ? (
              <Container>
                {apiconfigs.configs && apiconfigs.configs.length === 0 ? (
                  <span>
                    <br />
                    <br />
                    No configs yet. Load a configuration file to start.{' '}
                  </span>
                ) : (
                  <Table size="small">
                    <TableHead>
                      <TableRow style={{ backgroundColor: '#464C54' }}>
                        <TableCell>
                          <TableSortLabel
                            style={{
                              color: '#fff',
                              textTransform: 'uppercase',
                              fontSize: 12,
                            }}
                          >
                            Filename
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            style={{
                              color: '#fff',
                              textTransform: 'uppercase',
                              fontSize: 12,
                            }}
                          >
                            Customer
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            style={{
                              color: '#fff',
                              textTransform: 'uppercase',
                              fontSize: 12,
                            }}
                          >
                            Description
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            style={{
                              color: '#fff',
                              textTransform: 'uppercase',
                              fontSize: 12,
                            }}
                          >
                            Date Created
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            style={{
                              color: '#fff',
                              textTransform: 'uppercase',
                              fontSize: 12,
                            }}
                          >
                            Actions
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {apiconfigs.configs ? (
                      this.ConfigList(apiconfigs.configs)
                    ) : (
                      <div>Loading...</div>
                    )}
                  </Table>
                )}
                <ConfirmDialogWrapped
                  open={confirmDialogOpen}
                  onClose={this.handleConfirmDialogClose}
                />
              </Container>
            ) : (
              <Container>
                <Table>
                  <TableBody>&nbsp;&nbsp;&nbsp;Not logged on</TableBody>
                </Table>
              </Container>
            )}
          </Box>
        </Paper>
      </Container>
    );
  }
}

ConfigsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withParser(ConfigsPage)),
  ),
);
